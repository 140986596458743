import { render, staticRenderFns } from "./project-details.vue?vue&type=template&id=37d6d781&scoped=true&"
import script from "./project-details.vue?vue&type=script&lang=js&"
export * from "./project-details.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./project-details.vue?vue&type=style&index=1&id=37d6d781&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d6d781",
  null
  
)

export default component.exports