<template>
    <div>
        <Breadcrumbs main="Project" title="Project Detail" />
    
        <div class="container-fluid jkanban-container">
            <!-- Project Details -->
            <div class="row">
                <div class="col-12" v-if="!detailLoad && !detailError">
                    <div class="card">
                        <div class="card-header title-button-container">
                            <div style="display: flex; flex-direction: column; gap: 10px;">
                                <div style="display: flex; flex-direction: row; gap: 10px;">
                                    <h5>{{ project.title }}</h5>
                                    <router-link :to="{ path: '/project/edit/' + project.id }">
                                        <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer; padding-top: 2px;" v-b-tooltip.hover title="Edit"></feather>
                                    </router-link>
                                </div>
                                <div style="display: flex; flex-direction: row; gap: 8px;">
                                    <div> 
                                        <!-- <button v-if="client_by_project.length > 0" v-b-modal.modal-scrollable v-b-tooltip.hover title="Client" class="btn btn-secondary">Client List</button> -->
                                        <button v-b-modal.modal-scrollable v-b-tooltip.hover title="Client" class="btn btn-secondary">Add Client</button>
                                    </div>
                                    <div>
                                        <!-- <button v-b-modal.modal-scrollable-vendor v-b-tooltip.hover title="Vendor" class="btn btn-primary">Vendor List</button> -->
                                        <button v-b-modal.modal-scrollable v-b-modal.modal-scrollable-vendor v-b-tooltip.hover title="Vendor" class="btn btn-primary">Add Vendor</button>
                                    </div>
                                </div>
                            </div>
    
                            <div class="upper-button-container">
                                <div class="iconStyle">
                                    <feather type="layout"></feather>
                                </div>
    
                                <div class="description-container" v-b-modal.modal-prevent-budget @click="budgetDetails()">
                                    <div class="description-inner">
                                        <div style="margin-right: 16px; padding-top: 6px;">
                                            <feather type="layout"></feather>
                                        </div>
                                        <span class="text-style" style="color: white; font-size: medium;">Budget</span>
                                    </div>
                                </div>
    
                                <div class="iconStyle">
                                    <feather type="book-open"></feather>
                                </div>
    
                                <div class="description-container" v-b-modal.modal-prevent-quotation @click="QuotationDetails()">
                                    <div class="description-inner">
                                        <div style="margin-right: 16px; padding-top: 6px;">
                                            <feather type="book-open"></feather>
                                        </div>
                                        <span class="text-style" style="color: white; font-size: medium;">Quotation</span>
                                    </div>
                                </div>
    
                                <div class="iconStyle">
                                    <feather type="book"></feather>
                                </div>

                                <div class="description-container" v-b-modal.modal-prevent-invoice @click="InvoiceDetails()">
                                    <div class="description-inner">
                                        <div style="margin-right: 16px; padding-top: 6px;">
                                            <feather type="book"></feather>
                                        </div>
                                        <span class="text-style" style="color: white; font-size: medium;">Invoice</span>
                                    </div>
                                </div>
    
                                <div class="iconStyle">
                                    <feather type="minus-square"></feather>
                                </div>

                                <div class="description-container" v-b-modal.modal-prevent-requisition @click="RequisitionDetails()">
                                    <div class="description-inner">
                                        <div style="margin-right: 16px; padding-top: 6px;">
                                            <feather type="minus-square"></feather>
                                        </div>
                                        <span class="text-style" style="color: white; font-size: medium;">Requisition</span>
                                    </div>
                                </div>
    
                                <div class="iconStyle">
                                    <feather type="package"></feather>
                                </div>

                                <div class="description-container" v-b-modal.modal-prevent-purchase @click="PurchaseOrderDetails()">
                                    <div class="description-inner">
                                        <div style="margin-right: 16px; padding-top: 6px;">
                                            <feather type="package"></feather>
                                        </div>
                                        <span class="text-style" style="color: white; font-size: medium;">Purchase Order</span>
                                    </div>
                                </div>
    
                                <div class="iconStyle">
                                    <feather type="dollar-sign"></feather>
                                </div>

                                <div class="description-container" v-b-modal.modal-prevent-bill @click="BillDetails()">
                                    <div class="description-inner">
                                        <div style="margin-right: 16px; padding-top: 6px;">
                                            <feather type="dollar-sign"></feather>
                                        </div>
                                        <span class="text-style" style="color: white; font-size: medium;">Bill</span>
                                    </div>
                                </div>
    
                                <div class="iconStyle">
                                    <feather type="file"></feather>
                                </div>

                                <div class="description-container" v-b-modal.modal-prevent-rfp @click="RFPDetails()">
                                    <div class="description-inner">
                                        <div style="margin-right: 16px; padding-top: 6px;">
                                            <feather type="file"></feather>
                                        </div>
                                        <span class="text-style" style="color: white; font-size: medium;">RFP</span>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="card-body">
                            <div v-html="project.details"></div>
                            <div>
                                <div class="row">
                                    <div class="mt-4 col col-lg-8">
                                        <div style="display: flex; flex-direction: row; align-items: center;">
                                            <div style="width: 140px;">
                                                <span>Tasks</span>
                                            </div>
                                            <div style="font-size: medium;">{{ project.task }}</div>
                                        </div>
    
                                        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px; margin-bottom: 4px;">
                                            <div style="width: 140px;">
                                                <span>Resolved</span>
                                            </div>
                                            <div style="font-size: medium;">{{ project.resolved }}</div>
                                        </div>
    
                                        <div style="display: flex; flex-direction: row; align-items: center;">
                                            <div style="width: 140px;">
                                                <span>Remaining</span>
                                            </div>
                                            <div style="font-size: medium;">{{ project.remaining }}</div>
                                        </div>
    
                                        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px;">
                                            <div style="width: 140px;">
                                                <span>Priority</span>
                                            </div>
                                            <span v-if="project.priority == 'MEDIUM'" class="badge" :class="'badge-primary'" v-b-modal.modal-prevent-closing-status v-b-tooltip.hover title="Change Priority">{{ project.priority }}</span>
                                            <span v-if="project.priority == 'LOW'" class="badge" :class="'badge-danger'" v-b-modal.modal-prevent-closing-status title="Change Priority">{{ project.priority }}</span>
                                            <span v-if="project.priority == 'HIGH'" class="badge" :class="'badge-success'" v-b-modal.modal-prevent-closing-status title="Change Priority">{{ project.priority }}</span>
                                        </div>
    
                                        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px;">
                                            <div style="width: 140px;">
                                                <span>Start Date</span>
                                            </div>
                                            <div style="font-size: medium;">{{ project.start_date }}</div>
                                        </div>
    
                                        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px;">
                                            <div style="width: 140px;">
                                                <span>Deadline</span>
                                            </div>
                                            <div style="font-size: medium;">{{ project.deadline }}</div>
                                        </div>
    
                                        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px; margin-bottom: 4px;">
                                            <div style="width: 140px;">
                                                <span>Project Lead</span>
                                            </div>
                                            <div v-for="(team, index) in project.teammates" :key="index">
                                                <div v-if="team.project_lead == true">
                                                    <div style="font-size: medium;">{{ team.user_name }}</div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div style="display: flex; flex-direction: column; align-items: start; margin-top: 4px; margin-bottom: 4px;">
                                            <div style="width: 120px;">
                                                <span>Members</span>
                                            </div>
                                            <div style="display: grid; grid-template-columns: repeat(auto-fit, minmax(0, 52px)); gap: 4px;" class="col-md-12">
                                                <ul style="display: flex; gap: 8px;" v-for="(team, index) in project.teammates" :key="index">
                                                    <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                        <img class="img-50 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                    </li>
                                                    <li v-else class="d-inline-block" v-b-tooltip.hover :title="team.user_name"><img class="img-50 rounded-circle" src="../../../assets/images/user/3.jpg" alt="" data-original-title=""></li>
                                                </ul>
                                            </div>
                                            <button class="mt-3" style=" padding: 4px 8px; background-color: #ff595d; color: white; border-color: #ff595d; border-radius: 0.25rem;" v-b-modal.modal-1-assign>
                                                Add New
                                            </button>
                                        </div>
                                    </div>

                                    <div class="mt-4 col col-lg-4">
                                        <div>
                                            <div class="d-flex justify-content-between ">
                                                <h6 class="mb-3">Supporting Documents</h6>
                                                <feather type="plus-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-modal.modal-scrollable-add-document v-b-tooltip.hover title="Add Documents"> </feather>
                                            </div>
    
                                            <b-table show-empty sticky-header :items="project.project_attach.slice(0,3)" :fields="projectAttachment" head-variant="light">
                                                <template #cell(index)="data">
                                                     {{ data.index + 1 }}
                                                </template>
    
                                                <template #cell(title)="data">
                                                    <a :href="host+data.item.project_attachment">{{ truncateText(data.item.attach_title, 20) }}</a>
                                                </template>
                                                <template #cell(action)="data">
    
                                                    <div style="display: flex; flex-direction: row; gap:10px">
    
                                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete" @click="deleteAttach(data.item.id)"></feather>
                                                    </div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-else>
                    <px-card>
                        <div class="loader-box">
                            <div class="loader-10"></div>
                        </div>
                    </px-card>
                </div>
            </div>
    
            <!-- Task Board / Kanban -->
            <div class="row">
                <div class="px-3" style="min-width: 100%" v-if="!task_loading && !task_Error">
                    <div class="card">
                        <div class="card-header">
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <h5>Task List</h5>
                                <!-- <button class="btn btn-secondary" v-b-modal.modal-prevent-closing-add-task>Add Task</button> -->
                            </div>
                        </div>
                        <div style="display: flex; justify-items: center;">
                            <div class="card-body" style="display: flex; flex-direction: row; overflow-x: scroll;" v-if="kanbanShow">
                                <kanban-board :stages="categories" :blocks="tasks" @update-block="afterMove">
                                    <div v-for="stage in categories" :slot="stage" :key="stage">
                                        <div style="display: flex; justify-content: space-between; align-items: center; font-weight: 500;">
                                            <div v-if="stage == 'Add New'"><input type="text" class="form-control" v-model="name"></div>
                                            <div v-else style="padding-top: 8px; padding-bottom: 8px;">{{ stage }}</div>
    
                                            <b-button v-if="stage == 'Add New'" variant="primary" style="padding-top: 3px; padding-bottom: 3px; padding-left: 10px; padding-right: 10px;" @click="addStage()">
                                                <p style="font-size: large;">+</p>
                                            </b-button>
    
                                            <div v-if="stage !== 'Add New'" style="display: flex; align-items: center; padding: 4px; cursor: pointer;">
                                                <div class="dropdown">
                                                    <span style="margin-left: 12px;">
                                                        <feather type="more-horizontal"></feather>
                                                    </span>
                                                    <div class="dropdown-content">
                                                        <h7 v-b-modal.modal-center-edit-label @click="getLabelName(stage)">Edit</h7>
                                                        <h7 v-b-modal.modal-center-delete-label @click="getLabelName(stage)">Delete</h7>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div v-for="task in tasks" :slot="task.id" :key="task.id">
                                        <a class="kanban-box" @click="getTaskTitle(task.id)" v-if="task.title.toUpperCase()!='ADD NEW'" @mouse="afterMove(task)">
                                            <span>
                                                <div v-b-modal.modal-xl>
                                                    <span class="date">{{ task.start_date }} </span>
                                                </div>
    
                                                <span style="margin-top: -22px; cursor: pointer;" class="badge f-right badge-danger" v-if="task.priority=='1'" @click="toggleTaskPriority(task.id, 3)">LOW</span>
                                                <span style="margin-top: -22px; cursor: pointer;" class="badge f-right badge-primary" v-else-if="task.priority=='2'" @click="toggleTaskPriority(task.id, 1)">MEDIUM</span>
                                                <span style="margin-top: -22px; cursor: pointer;" class="badge f-right badge-success" v-else-if="task.priority=='3'" @click="toggleTaskPriority(task.id, 2)">HIGH</span>
    
                                                <div v-b-modal.modal-xl>
                                                    <h6 class="my-3">{{task.title}}</h6>
                                                    <div class="d-flex">
                                                        <ul style="display: flex; flex-direction: row; gap: 10px; align-items: center; justify-items: center;">
                                                            <li v-b-tooltip.hover title="Total" style="display: flex; align-items: center; gap: 4px;">
                                                                <feather type="target" style="height: 14px;"></feather> {{ task.subtask_count.total }}
                                                            </li>
                                                            <li v-b-tooltip.hover title="Pending" style="display: flex; align-items: center; gap: 4px;">
                                                                <feather type="info" style="height: 14px;"></feather> {{ task.subtask_count.pending }}
                                                            </li>
                                                            <li v-b-tooltip.hover title="Completed" style="display: flex; align-items: center; gap: 4px;">
                                                                <feather type="check-circle" style="height: 14px;"></feather> {{ task.subtask_count.complete }}
                                                            </li>
                                                        </ul>
    
                                                        <div class="customers">
                                                            <ul>
                                                                <li class="d-inline-block mr-2" v-if="task.teammates.length > 3">
                                                                    <p class="f-12">+ {{ task.teammates.length - 3 }}</p>
                                                                </li>
                                                                <ul v-for="(member, index) in task.teammates.slice(0,3)" :key="index">
                                                                    <li v-if="member.member_id.additional_info" class="d-inline-block" v-b-tooltip.hover :title="member.user_name"><img class="img-20 rounded-circle" :src="host + member.member_id.additional_info.photo" alt="" data-original-title=""></li>
                                                                    <li v-else class="d-inline-block" v-b-tooltip.hover :title="member.user_name"><img class="img-20 rounded-circle" src="../../../assets/images/user/3.jpg" alt="" data-original-title=""></li>
                                                                </ul>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </a>
    
                                        <a class="kanban-box not-draggable" v-else>
                                            <b-form-input id="title-input" v-model="task.new_title" placeholder="Enter Title"></b-form-input>
                                            <b-button @click="addTask(task.label, task.new_title)" class="btn btn-primary mt-3">Add</b-button>
                                        </a>
                                    </div>
                                </kanban-board>
    
                                <b-modal id="modal-xl" ref="taskmodal" size="xl" :title="task_details.title" @show="resetBoroModal" @hidden="resetBoroModal" hide-footer>
                                    <template #modal-header="{ close }">
                                        <div style="display: flex; gap: 15px">
                                            <h5>{{ task_details.title }} </h5>
                                            <feather v-b-modal.modal-center-main size="22px" @click="deleteMainTask(task_details.id)" type="trash-2" v-b-tooltip.hover title="Delete" style="color: #ff595d; cursor: pointer;"></feather>
                                        </div>
    
                                        <feather type="x" @click="close()"></feather>
                                        <!-- <b-button size="sm" variant="outline-danger" @click="close()">
                                            Close Modal
                                        </b-button> -->
                                    </template>
    
                                    <div class="d-flex flex-column flex-lg-row" style="gap: 5px;">
                                        <div class="w-100">
                                            <div>
                                                <div class="task-board-task-scroll">
                                                    <div style="display: flex; flex-direction: column; gap: 15px; height: 100%; overflow-y: auto; padding-right: 12px;">
                                                        <div v-if="isEditable" style="border-style: solid; border-width: 2px; border-color: #fff0f0; padding: 10px; border-radius: 0.35rem;">
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <div class="form-group">
                                                                        <label>Task Title</label>
                                                                        <input class="form-control" type="text" placeholder="Enter task title" v-model="detail.title">
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            <div class="row">
                                                                <div class="col-sm-5">
                                                                    <div class="form-group">
                                                                        <label>Start date</label>
                                                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="detail.start_date"></datepicker>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <div class="form-group">
                                                                        <label>Deadline</label>
                                                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="detail.end_date"></datepicker>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            <div class="row">
                                                                <div class="col">
                                                                    <div class="form-group">
                                                                        <label>Task Details</label>
                                                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter task details..." rows="4" v-model="detail.description"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            <div class="row" style="margin-bottom: 12px;">
                                                                <div class="col">
                                                                    <div class="form-group mb-0">
                                                                        <button @click="onSubmit" class="btn btn-success mr-3">Submit</button>
                                                                        <button @click="cancelEditable" class="btn btn-danger" href="#">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            <!-- <div v-if="task_details.teammates !== null" style="margin-bottom: 8px;">
                                                                <div class="row">
                                                                    <div class="text-center col-sm-6 w-full" v-for="user in task_details.teammates" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-assetallocation title="Delete">
                                                                        <div class="w-100 mb-2 badge-style">
                                                                            <h7>{{ user.user_name }}</h7>
                                                                            <b-icon icon="x" style="color: #ffffff;" @click="deleteAssignedMembers(user.id)"></b-icon>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                        </div>
    
                                                        <div v-else>
                                                            <div style="width: 100%; display: flex; justify-content: space-between;">
                                                                <div v-if="task_details.description" style="margin-bottom: 12px; margin-right: 12px;">
                                                                    {{ task_details.description }}
                                                                </div>
                                                                <div>
                                                                    <feather @click="editTaskDetails(task_details)" type="edit-3" v-b-tooltip.hover title="Edit" style="height: 20px; cursor: pointer;"></feather>
                                                                </div>
                                                            </div>
    
                                                            <div style="display: flex; gap: 28px;">
                                                                <span v-if="task_details.start_date">
                                                                    <h6>Start Date</h6>{{ task_details.start_date }}
                                                                </span>
                                                                <span v-if="task_details.end_date">
                                                                    <h6>Deadline</h6>{{ task_details.end_date }}
                                                                </span>
                                                            </div>
    
                                                            <div style="display: flex; gap: 4px; margin-top: 16px;">
                                                                <ul v-for="(member, index) in task_details.teammates" :key="index">
                                                                    <li v-if="member.member_id.additional_info" class="d-inline-block" v-b-tooltip.hover :title="member.user_name"><img class="img-30 rounded-circle" :src="host + member.member_id.additional_info.photo" alt="" data-original-title=""></li>
                                                                    <li v-else class="d-inline-block" v-b-tooltip.hover :title="member.user_name"><img class="img-30 rounded-circle" src="../../../assets/images/user/3.jpg" alt="" data-original-title=""></li>
                                                                </ul>
    
                                                                <div style="display: flex; align-items: center; margin-left: 6px;">
                                                                    <button style="background-color: #ff595d; color: white; border-color: #ff595d; border-radius: 0.25rem;" @click="assignMemberModal(null,'from_task')" type="submit" v-b-modal.modal-1>
                                                                        Add Member
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
    
                                                        <!-- Sub Task List -->
                                                        <div>
                                                            <div v-for="sub in sub_tasks" :slot="sub.id" :key="sub.id">
                                                                <div class="mt-3 ml-4">
                                                                    <div class="task-container" style="gap: 16px;">
                                                                        <div style="display: flex; align-items: start;">
    
                                                                            <div class="mr-2" style="margin-top: 2px;">
                                                                                <input type="checkbox" id="myCheck" v-model="sub.complete" @click="myFunction(sub.id)">
                                                                            </div>
    
                                                                            <div>
                                                                                <h6 style="word-break: break-all">{{ sub.title }} <span class="ml-4 badge badge-danger" v-b-tooltip.hover title="Deadline">{{sub.end_date}}</span></h6>
    
                                                                                <p class="mt-2" style="word-break: break-all" v-html="convertUrls(sub.description)"></p>
                                                                                <!-- <div class="mt-2" style="word-break: break-all">
                                                                                    {{ sub.description }}
                                                                                </div> -->
                                                                                <div style="display: flex; gap: 4px; margin-top: 6px;">
                                                                                    <ul v-for="(member, index) in sub.teammates" :key="index">
                                                                                        <li v-if="member.member_id.additional_info" class="d-inline-block" v-b-tooltip.hover :title="member.user_name"><img class="img-30 rounded-circle" :src="host + member.member_id.additional_info.photo" alt="" data-original-title=""></li>
                                                                                        <li v-else class="d-inline-block" v-b-tooltip.hover :title="member.user_name"><img class="img-30 rounded-circle" src="../../../assets/images/user/3.jpg" alt="" data-original-title=""></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div style="display: flex; gap: 10px;">
                                                                            <feather @click="assignMemberModal(sub,'from_subtask')" type="user-plus" v-b-tooltip.hover title="Assign Member" style="height: 15px; cursor: pointer;" v-b-modal.modal-1></feather>
                                                                            <feather @click="editSubTask(sub)" type="edit-3" v-b-tooltip.hover title="Edit" style="height: 15px; cursor: pointer;" v-b-modal.modal-1></feather>
                                                                            <feather v-b-modal.modal-center @click="deleteSubTask(sub.id)" type="x-square" v-b-tooltip.hover title="Delete" style="height: 15px; cursor: pointer;"></feather>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
    
                                                        <!-- <b-form-textarea class="form-control" id="textarea-auto-height" placeholder="Enter description" rows="2" max-rows="10" v-model="sub.description" style="font-size: small;">
                                                                </b-form-textarea> -->
                                                        <!-- Sub Task Create -->
                                                        <div class="ml-2">
                                                            <div style="display: flex; flex-direction: column; gap: 10px;">
                                                                <input class="form-control" type="text" placeholder="Enter title" v-model="sub.title" style="font-size: small;">
    
                                                                <div style="word-break: break-all;">
                                                                    <vue-editor v-model="sub.description"></vue-editor>
                                                                </div>
    
                                                                <!-- <div class="ck-editor-container ck-editor__editable">
                                                                    <ckeditor :editor="editor" placeholder="Enter subtask details..." v-model="sub.description"></ckeditor>
                                                                </div> -->
                                                            </div>
    
                                                            <div style="margin-top: 10px; display: flex; gap: 12px; width: 100%;">
                                                                <div style="width: 80%;"></div>
                                                                <b-button @click="createSubTask" variant="success" style="width: 120px;">Create</b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="w-100">
                                            <div>
                                                <div class="chatbox my-lg-0 my-2">
                                                    <div class="chat">
                                                        <div class="avatar">
                                                            <svg fill="#000000" height="34px" width="34px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 297 297" xml:space="preserve">
                                                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                                <g id="SVGRepo_iconCarrier">
                                                                    <path d="M269.415,105.583h-31.808V54.592c0-17.378-14.137-31.516-31.514-31.516H31.511C14.136,23.076,0,37.214,0,54.592v95.409 c0,17.377,14.136,31.515,31.511,31.515h22.933v32.989c0,4.011,2.415,7.626,6.121,9.16c1.227,0.508,2.515,0.755,3.792,0.755 c2.58,0,5.117-1.008,7.013-2.905l27.619-27.625v17.343c0,15.213,12.375,27.59,27.587,27.59h76.762l32.191,32.196 c1.896,1.898,4.433,2.905,7.014,2.905c1.277,0,2.564-0.247,3.791-0.755c3.706-1.534,6.121-5.149,6.121-9.16v-25.186h16.961 c15.211,0,27.585-12.377,27.585-27.59v-78.061C297,117.959,284.626,105.583,269.415,105.583z M64.357,161.686H31.511 c-6.44,0-11.681-5.242-11.681-11.684V54.592c0-6.443,5.24-11.686,11.681-11.686h174.583c6.442,0,11.684,5.242,11.684,11.686v95.409 c0,6.442-5.241,11.684-11.684,11.684h-98.841c-2.629,0-5.151,1.045-7.012,2.905l-25.969,25.974v-18.964 C74.272,166.125,69.833,161.686,64.357,161.686z M277.17,211.232c0,4.278-3.478,7.761-7.755,7.761H242.54 c-5.475,0-9.915,4.438-9.915,9.914v11.162l-18.17-18.172c-1.858-1.86-4.381-2.904-7.011-2.904h-80.869 c-4.277,0-7.757-3.482-7.757-7.761v-29.717h87.275c17.377,0,31.514-14.138,31.514-31.515v-24.588h31.808 c4.276,0,7.755,3.48,7.755,7.759V211.232z"></path>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div class="message">
                                                            <p class="text-black font-weight-bold" style="font-size: 18px;">Discussions</p>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="task-board-scroll">
                                                    <div class="task-board-comments">
                                                        <div v-for="(comment, index) in comments_list" :key="index">
                                                            <div class="chatbox">
                                                                <div class="chat">
                                                                    <div class="avatar">
                                                                        <img :src="host + comment.commenter_photo" alt="" v-b-tooltip.hover>
                                                                    </div>
                                                                    <div class="message">
                                                                        <h7 class="name pl-1">{{ comment.commenter }}</h7>
                                                                        <p class="msg" v-html="convertUrls(comment.comment)"></p>
                                                                        <!-- <p class="msg" v-b-tooltip.hover> {{ comment.comment }}</p> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                    <div class="task-board-comment-box">
                                                        <div style="display: flex; gap: 12px;">
                                                            <input class="form-control" type="text" v-model="comment.comment" placeholder="Write your comment here..." @keyup.enter="addComment()">
                                                            <button @click="addComment" class="btn btn-success">Comment</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-modal>
    
                                <b-modal id="modal-1" :title="crud_title" hide-footer>
                                    <div class="my-2">
                                        <div v-if="crud_title == 'Edit'">
                                            <div style="display: flex; flex-direction: column; gap: 15px;">
                                                <input class="form-control" type="text" placeholder="Enter title" v-model="edit_sub.title">
    
                                                <div style="word-break: break-all;">
                                                    <vue-editor v-model="edit_sub.description"></vue-editor>
                                                </div>
    
                                                <!-- <ckeditor :editor="editor" placeholder="Enter subtask details..." v-model="edit_sub.description"></ckeditor> -->
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label>Deadline</label>
                                                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="edit_sub.end_date"></datepicker>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter description..." rows="3" v-model="edit_sub.description"></textarea> -->
                                                <button @click="updateSubTask" class="btn btn-primary" style="width: 120px;">Submit</button>
                                            </div>
                                        </div>

                                        <div v-if="crud_title == 'Assign Member'">
                                            <div v-if="type === 'from_task'">
                                                <div v-if="task_details.teammates !== null" style="margin-bottom: 8px;">
                                                    <div class="row">
                                                        <div class="text-center col-sm-6 w-full" v-for="user in task_details.teammates" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-assetallocation title="Delete">
                                                            <div class="w-100 mb-2 badge-style">
                                                                <h7>{{ user.user_name }}</h7>
                                                                <b-icon icon="x" style="color: #ffffff;" @click="deleteAssignedMembers(user.id)"></b-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="type === 'from_subtask'">
                                                <div v-if="subtask_teammates !== null" style="margin-bottom: 8px;">
                                                    <div class="row">
                                                        <div class="text-center col-sm-6 w-full" v-for="user in subtask_teammates" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-assetallocation title="Delete">
                                                            <div class="w-100 mb-2 badge-style">
                                                                <h7>{{ user.user_name }}</h7>
                                                                <b-icon icon="x" style="color: #ffffff;" @click="deleteAssignedMembers(user.id)"></b-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div style="display: flex; flex-direction: column; gap: 15px;">
                                                <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="user" :max="project_member_list.length" track-by="user" :options="formattedProjectMember" :multiple="true" :taggable="true"></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </b-modal>
    
                                <!-- Assign Emloyee -->
                                <b-modal id="modal-1-assign" title="Assign Employee" hide-footer>
                                    <div class="my-2">
                                        <h6>Already Assigned</h6>
                                        <div class="row">
                                            <div class="text-center col-sm-6 w-full" v-for="user in project_detail.teammates" :key="user.id" v-b-tooltip.hover @click="deletetAllocation(user.id)" title="Delete">
                                                <div class="w-100 mb-2 badge-style">
                                                    <h7>{{ user.user_name}}</h7>
                                                    <b-icon icon="x" style="color: white;"></b-icon>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div>
                                            <div style="display: flex; flex-direction: column; gap: 15px;">
                                                <multiselect v-model="assignedProjectMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="user_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
                                                <!-- <div class="d-flex justify-content-end ">
                                                    <button @click="assignProjectMember" class="btn btn-primary" style="width: 120px;">Assign</button>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </b-modal>
                            </div>
                            <div v-else class="card-body" style="height: 600px;">
    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-3" style="width: 100%;" v-else>
                    <div class="card" style="min-height: 500px;">
                        <div class="card-body d-flex align-items-center justify-content-center">
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <!-- Add Task -->
            <b-modal id="modal-prevent-closing-add-task" ref="modal" title="Add New Task" @show="resetTaskModal" @hidden="resetTaskModal" @ok="handleTaskOk">
                <form ref="form" @submit.stop.prevent="handleTaskSubmit">
                    <b-form-group label="Title" label-for="name-input" invalid-feedback="Title is required" :state="nameState">
                        <b-form-input id="name-input" v-model="task.title" :state="nameState" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Label" label-for="name-input" invalid-feedback="Label is required" :state="nameState">
                        <b-form-select v-model="task.label_name" :options="categories_options"></b-form-select>
                    </b-form-group>
                </form>
            </b-modal>
    
            <!-- Edit Label -->
            <b-modal id="modal-center-edit-label" ref="modal" title="Edit Label" @ok="handleLabelOk">
                <form ref="form" @submit.stop.prevent="handleLabelSubmit">
                    <b-form-group label="Label" label-for="name-input" invalid-feedback="Label is required">
                        <b-form-input id="name-input" v-model="new_label_name"></b-form-input>
                    </b-form-group>
                </form>
            </b-modal>
    
            <!-- Delete Modal -->
            <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
                <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this sub task?</div>
            </b-modal>
            <b-modal id="modal-center-main" centered hide-header @ok="handleTaskDeleteOk">
                <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this task?</div>
            </b-modal>
            <b-modal id="modal-center-delete-label" centered hide-header @ok="deleteTaskLabel()">
                <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this label?</div>
            </b-modal>
    
            <!-- Budget Modal -->
            <b-modal id="modal-prevent-budget" size="xl" ref="modal" title="Budget Request" hide-footer>
                <button class="btn btn-primary mb-4" type="submit" @click="toBudgetRequest">
                    <h7>Crate a budget request</h7>
                </button>
    
                <div>
                    <b-table show-empty :items="budgetList" :fields="tableBudget" head-variant="light">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(title)="data">
                            <span>{{ data.item.title?.substring(0, 50) }}</span>
                        </template>
                        <template #cell(budget_category)="data">
                            <span>{{ data.item.budget_category?.title }}</span>
                        </template>
                        <template #cell(requested_by)="data">
                            <span>{{ data.item.requested_by?.username }}</span>
                        </template>
                        <template #cell(status)="data">
                            <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                            <span v-if="data.item.status == 'REJECTED'" class="badge" :class="'badge-danger'">{{ data.item.status }}</span>
                            <span v-if="data.item.status == 'PENDING'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                        </template>
                    </b-table>
                </div>
            </b-modal>
    
            <!-- Quotation Modal -->
            <b-modal id="modal-prevent-quotation" size="xl" ref="modal" title="Quotation" hide-footer>
                <button class="btn btn-primary mb-4" type="submit" @click="toQuotationsRequest">
                    <h7>Crate a quotation</h7>
                </button>
    
                <div>
                    <b-table show-empty :items="quotationList" :fields="tableQuotation" head-variant="light">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(client_name)="data">
                            <span>{{ data.item.client?.username }}</span>
                        </template>
                        <template #cell(created_by_name)="data">
                            <span>{{ data.item.created_by?.username }}</span>
                        </template>
                        <template #cell(quotation_id)="data">
                            <router-link :to="`/finance-and-accounts/finance/quotation/${data.item.id}`" class="link-style">{{ data.item.quotation_id }}</router-link>
                        </template> 
                    </b-table>
                </div>
            </b-modal>
    
            <!-- Invoice Modal -->
            <b-modal id="modal-prevent-invoice" size="xl" ref="modal" title="Invoices" hide-footer>
                <button class="btn btn-primary mb-4" type="submit" @click="toInvoiceRequest">
                    <h7>Crate a Invoice</h7>
                </button>
    
                <div>
                    <b-table show-empty :items="invoiceList" :fields="tableInvoice" head-variant="light">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(invoice_id)="data">
                            <span>
                                <router-link :to="`/finance-and-accounts/finance/invoice/${data.item.invoice_id}`" class="link-style">
                                    {{ data.item.invoice_id }}
                                </router-link>
                            </span>
                        </template>
                        <template #cell(status)="data">
                            <span v-if="data.item.status == 'FULLY-PAID'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                            <span v-if="data.item.status == 'DUE'" class="badge" :class="'badge-warning'">{{ data.item.status }}</span>
                            <span v-if="data.item.status == 'PARTIALLY-PAID'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                        </template>
                    </b-table>
                </div>
            </b-modal>
    
            <!-- Requisition Modal -->
            <b-modal id="modal-prevent-requisition" size="xl" ref="modal" title="Requisitions" hide-footer>
                <button class="btn btn-primary mb-4" type="submit" @click="toRequisitionRequest">
                    <h7>Crate a requisition</h7>
                </button>
    
                <div>
                    <b-table show-empty :items="requisitionList" :fields="tableRequisition" head-variant="light">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(requisition_id)="data">
                            <span>
                                <router-link :to="`/finance-and-accounts/account/requisition/view/${data.item.id}`" class="link-style">
                                    {{ data.item.requisition_id }}
                                </router-link>
                            </span>
                        </template>
                        <template #cell(raised_by)="data">
                            <span>{{ data.item.raised_by?.username }}</span>
                        </template>
                        <template #cell(status)="data">
                            <span v-if="data.item.status == 'PENDING'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                            <span v-if="data.item.status == 'DUE'" class="badge" :class="'badge-warning'">{{ data.item.status }}</span>
                            <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-success'">{{ data.item.status }}</span>
                            <span v-if="data.item.status == 'PARTIALLY-PAID'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                        </template>
                    </b-table>
                </div>
            </b-modal>
    
            <!-- Purchase Modal -->
            <b-modal id="modal-prevent-purchase" size="xl" ref="modal" title="Purchase Orders" hide-footer>
                <button class="btn btn-primary mb-4" type="submit" @click="toPurchaseOrderRequest">
                    <h7>Crate a purchase order</h7>
                </button>
    
                <div>
                    <b-table show-empty :items="purchaseOrderList" :fields="tablePurchaseOrder" head-variant="light">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(vendor)="data">
                            <span>{{ data.item.vendor?.username }}</span>
                        </template>
                        <template #cell(created_by)="data">
                            <span>{{ data.item.created_by?.username }}</span>
                        </template>
                        <template #cell(purchase_order_id)="data">
                            <span>
                                <router-link :to="`/finance-and-accounts/finance/purchase-order/${data.item.id}`" class="link-style">
                                    {{ data.item.purchase_order_id }}
                                </router-link>
                            </span>
                        </template>
                        <template #cell(status)="data">
                            <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                            <span v-if="data.item.status == 'DISMISSED'" class="badge" :class="'badge-danger'">{{ data.item.status }}</span>
                        </template>
                    </b-table>
                </div>
            </b-modal>
    
            <!-- Bill Modal -->
            <b-modal id="modal-prevent-bill" size="xl" ref="modal" title="Bills" hide-footer>
                <button class="btn btn-primary mb-4" type="submit" @click="toBillRequest">
                    <h7>Create a bill</h7>
                </button>
    
                <div>
                    <b-table show-empty :items="billList" :fields="tableBill" head-variant="light">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(bill_id)="data">
                            <span>
                                <router-link :to="`/finance-and-accounts/finance/bill/${data.item.id}`" class="link-style">
                                    {{ data.item.bill_id }}
                                </router-link>
                            </span>
                        </template>
                        <template #cell(status)="data">
                            <span v-if="data.item.status == 'DUE'" class="badge" :class="'badge-warning'">{{ data.item.status }}</span>
                            <span v-if="data.item.status == 'FULLY-PAID'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                            <span v-if="data.item.status == 'PARTIALLY-PAID'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                        </template>
                    </b-table>
                </div>
            </b-modal>
    
            <!-- RFP Modal -->
            <b-modal id="modal-prevent-rfp" size="xl" ref="modal" title="RFP" hide-footer>
                <button class="btn btn-primary mb-4" type="submit" @click="toRFPRequest">
                    <h7>Create a RFP</h7>
                </button>
    
                <div>
                    <b-table show-empty :items="rfpList" :fields="tableRFP" head-variant="light">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(rfp_id)="data">
                            <span>
                                <router-link :to="`/procurement/rfp/${data.item.id}`" class="link-style">
                                    {{ data.item.rfp_id }}
                                </router-link>
                            </span>
                        </template>
                        <template #cell(title)="data">
                            <span>{{ data.item.title?.substring(0, 50) }}</span>
                        </template>
                        <template #cell(rfp_category)="data">
                            <span>{{ data.item.rfp_category?.title }}</span>
                        </template>
                        <template #cell(status)="data">
                            <span v-if="data.item.status == 'WORKING'" class="badge" :class="'badge-warning'">{{ data.item.status }}</span>
                            <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                            <span v-if="data.item.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ data.item.status }}</span>
                        </template>
                    </b-table>
                </div>
            </b-modal>
    
            <!-- Update Project Priorit Status -->
            <b-modal id="modal-prevent-closing-status" ref="modal" title="Update Project Priority" @ok="handleStatusOk">
                <form ref="form" @submit.stop.prevent="handleStatusSubmit">
                    <b-form-group label="Priority" label-for="name-input">
                        <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
                    </b-form-group>
                </form>
            </b-modal>
    
            <!-- Client Create Modal -->
            <b-modal id="modal-scrollable" ref="modal" title="Add Client" hide-footer>
                <div style="display: flex; flex-direction: row; justify-content: space-between">
                    <h6>Already Added</h6>
                    <router-link to="/crm/client/create"><button style="background-color: #f42a6b; color: white; border-color: #f42a6b; border-radius: 0.25rem;">Create Client</button></router-link>
                </div>
                <div class="row my-2">
    
                    <div class="text-center col-sm-6 w-full" v-for="user in client_by_project" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation title="Delete" @click="deletetAllocationClient(user.id)">
                        <div class="w-100 mb-2 badge-style">
                            <h7>{{ user.company_name}}</h7>
                            <!-- <h7>{{ user.user_name}}</h7> -->
                            <b-icon icon="x" style="color: white;"></b-icon>
                        </div>
                    </div>
                </div>
                <multiselect v-model="assignedClient" tag-placeholder="Add member" placeholder="Search or add a member" label="user" :max="client_list.length" track-by="user.id" :options="formattedOptions" :multiple="true" :taggable="true"></multiselect>
            </b-modal>
    
            <!-- Vendor Create Modal -->
            <b-modal id="modal-scrollable-vendor" ref="modal" title="Add Vendor" hide-footer>
                <div style="display: flex; flex-direction: row; justify-content: space-between">
                    <h6>Already Added</h6>
                    <router-link to="/procurement/vendor/list"><button style="background-color: #2c323f; color: white; border-color: #2c323f; border-radius: 0.25rem;">Create Vendor</button></router-link>
                </div>
                <div class="row my-2">
                    <div class="text-center col-sm-6 w-full" v-for="user in vendor_by_project" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation title="Delete" @click="deletetAllocationVendor(user.id)">
                        <div class="w-100 mb-2 badge-style">
                            <h7>{{ user.user_name}}</h7>
                            <b-icon icon="x" style="color: white;"></b-icon>
                        </div>
                    </div>
                </div>
                <multiselect v-model="assignedVendor" tag-placeholder="Add member" placeholder="Search or add a member" label="user" :max="vendor_list.length" track-by="user.id" :options="formattedVendorOptions" :multiple="true" :taggable="true"></multiselect>
            </b-modal>
    
            <!-- Project Attachment Create -->
            <b-modal id="modal-scrollable-add-document" ref="modal" title="Add document" @ok="handleAttachOk">
                <form ref="form" @submit.stop.prevent="handleAttahmentSubmit">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Upload project file</label>
                                <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." @change="uploadAttachFile"></b-form-file>
                            </div>
                        </div>
                    </div>
    
                </form>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import Multiselect from 'vue-multiselect';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    import random from "vue-rate-it/glyphs/random";
    import Datepicker from 'vuejs-datepicker';
    import vue2Dropzone from 'vue2-dropzone';
    import moment from "moment";
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import { myHost } from "../../../_helpers/host";
    import { VueEditor } from "vue2-editor";
    
    export default {
        components: {
            Multiselect,
            vueDropzone: vue2Dropzone,
            Datepicker,
            VueEditor
        },
    
        data() {
            return {
                editor: ClassicEditor,
                kanbanShow: false,
                isEditable: false,
                detail: {},
                project: {},
                label_name: '',
                new_label_name: '',
                task: {
                    title: '',
                    label_name: '',
                    project: this.$route.params.id
                },
                task_details: {
                    id: null,
                    title: '',
                    description: '',
                    start_date: null,
                    end_date: null,
                    teammates: [],
                },
                categories: [],
                categories_options: [],
                name: '',
                task_detail: {},
                host: myHost.host,
    
                status: 'false',
                crud_title: '',
                delete_value: null,
                delete_task_value: null,
                sub: {
                    title: '',
                    description: ''
                },
                edit_sub: {
                    id: null,
                    title: '',
                    description: '',
                    end_date: '',
                },
    
                subtask_teammates: [],
                type: null,
    
                statuses: ['HIGH', 'MEDIUM', 'LOW'],
                selectedStatus: '',
    
                assignedProjectMembers: [],
                assignedMembers: [],
    
                assignedClient: [],
                assignedVendor: [],
    
                project_member: [],
                member: [],
                task_id: null,
                subtask_id: null,
                project_id: null,
                user_id: null,
    
                add_attachment: {
                    project_attachment: null,
                    project_id: '',
                },
    
                task_object: {
                    title: "",
                    details: "",
                    label: ""
                },
                nameState: null,
    
                tasks: [],
                sub_tasks: [],
    
                comment: {
                    task_id: null,
                    comment: ''
                },
                tableBudget: [
                    {
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'title',
                        label: 'Title',
                        sortable: true
                    },
                    {
                        key: 'budget_category',
                        label: 'Category',
                        sortable: true
                    },
                    {
                        key: 'requested_by',
                        label: 'Requested By',
                        sortable: true
                    },
                    {
                        key: 'budget_amount',
                        label: 'Budget Amount',
                        sortable: true
                    },
                    {
                        key: 'approved_amount',
                        label: 'Approved Amount',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: false
                    }
                ],
                tableQuotation: [
                    {
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'quotation_id',
                        label: 'Quotation ID',
                        sortable: true
                    },
                    {
                        key: 'client_name',
                        label: 'Client',
                        sortable: true
                    },
                    {
                        key: 'created_by_name',
                        label: 'Created By',
                        sortable: true
                    },
                    {
                        key: 'subtotal',
                        label: 'Subtotal',
                        sortable: true
                    }
                ],
                tableInvoice: [
                    {
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'invoice_id',
                        label: 'Invoice ID',
                        sortable: true
                    },
                    {
                        key: 'invoice_date',
                        label: 'Invoice Date',
                        sortable: true
                    },
                    {
                        key: 'due_date',
                        label: 'Due Date',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: true
                    },
                    {
                        key: 'subtotal',
                        label: 'Subtotal',
                        sortable: true
                    }
                ],
                tableRequisition: [
                    {
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'requisition_id',
                        label: 'Requisition ID',
                        sortable: true
                    },
                    {
                        key: 'raised_by',
                        label: 'Raised By',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: true
                    },
                    {
                        key: 'grand_total',
                        label: 'Total',
                        sortable: true
                    }
                ],
                tablePurchaseOrder: [
                    {
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'purchase_order_id',
                        label: 'Purchase Order ID',
                        sortable: true
                    },
                    {
                        key: 'vendor',
                        label: 'Vendor',
                        sortable: true
                    },
                    {
                        key: 'created_by',
                        label: 'Created by',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: true
                    },
                    {
                        key: 'subtotal',
                        label: 'Total',
                        sortable: true
                    }
                ],
                tableBill: [
                    {
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'bill_id',
                        label: 'Bill ID',
                        sortable: true
                    },
                    {
                        key: 'bill_date',
                        label: 'Bill Date',
                        sortable: true
                    },
                    {
                        key: 'due_date',
                        label: 'Due Date',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: true
                    },
                    {
                        key: 'subtotal',
                        label: 'Total',
                        sortable: true
                    }
                ],
                tableRFP: [
                    {
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'rfp_id',
                        label: 'RFP ID',
                        sortable: true
                    },
                    {
                        key: 'title',
                        label: 'Title',
                        sortable: true
                    },
                    {
                        key: 'rfp_category',
                        label: 'Category',
                        sortable: true
                    },
                    {
                        key: 'deadline',
                        label: 'Deadline',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: true
                    }
                ],
                projectAttachment: [
                    {
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'title',
                        label: 'Title',
                        sortable: false
                    },
                    {
                        key: 'action',
                        label: 'Action',
                        sortable: false,
                    }
                ]
            };
        },
    
        mounted() {
            const taskId = this.$route.query.task;
            if (taskId) {
                console.log(taskId);
                this.getTaskTitle(taskId);
            }
        },
    
        created() {
            this.$store.dispatch("project/fetchMyProjectDetails", this.$route.params.id).then(result => {
                if(result.code !== 200) {
                    Swal.fire({
                        title: "Error!",
                        text: result.response,
                        icon: "error"
                    });
                    setTimeout(() => {
                        this.$router.push("/project/my-project")
                    }, 4000)
                }
            })
            this.$store.dispatch("task/fetchTaskCategory", this.$route.params.id).then(result => {
                this.kanbanShow = true
            })
            this.$store.dispatch("client/fetchClient_without_pg");
            this.$store.dispatch("project/fetchClientByProject", this.$route.params.id);
            this.$store.dispatch("project/fetchVendorByProject", this.$route.params.id);
            this.$store.dispatch("vendor/fetchVendor_without_pg");
            this.$store.dispatch("employee/fetchEmployeeList");
            this.$store.dispatch("project/fetchProjectMemberList", this.$route.params.id);
        },
    
        computed: {
            ...mapState({
                task_category: state => state.task.task_category,
                sub_task_list: state => state.task.sub_task_list,
                task_details_for_update: state => state.task.task_detail,
                project_detail: state => state.project.project_detail,
                comments_list: state => state.task.comments,
                budgetList: state => state.project.budget_by_project,
                quotationList: state => state.project.quotation_by_project,
                invoiceList: state => state.project.invoice_by_project,
                requisitionList: state => state.project.requisition_by_project,
                purchaseOrderList: state => state.project.purchase_order_by_project,
                billList: state => state.project.bill_list_by_project,
                rfpList: state => state.project.rfp_list_by_project,
                client_list: state => state.client.client_without_pg,
                vendor_list: state => state.vendor.vendor_without_pg,
                client_by_project: state => state.project.client_by_project,
                vendor_by_project: state => state.project.vendor_by_project,
    
                user_list: state => state.employee.employee_list,
                project_member_list: state => state.project.projectMember,
                task_member_list: state => state.project.taskMember,
    
                detailLoad: state => state.project.detailLoading,
                detailError: state => state.project.detailError,
    
                task_loading: state => state.task.submitLoading,
                task_Error: state => state.task.submitError
            }),

            formattedOptions() {
                return this.client_list.map(client => ({
                    id: client.user.id,
                    user: client.company_name
                }));
            },

            formattedVendorOptions() {
                return this.vendor_list.map(vendor => ({
                    id: vendor.user.id,
                    user: vendor.user.first_name
                }));
            },

            formattedProjectMember() {
                if (this.type === 'from_subtask') {
                    return this.task_member_list.map(user => ({
                        id: user.user_id.id,
                        user: user.user_name
                    }));
                } else {
                    return this.project_member_list.map(user => ({
                        id: user.user_id.id,
                        user: user.user_name
                    }));
                }
            }
        },
    
        watch: {
            type(newValue, oldValue) {},

            assignedClient: {
                handler: function (newVal, oldVal) {
                    this.storeAssignedClient(newVal);
                },
                deep: true
            },
    
            assignedVendor: {
                handler: function (newVal, oldVal) {
                    this.storeAssignedVendor(newVal);
                },
                deep: true
            },
    
            project_detail(oldValue, newValue) {
                this.project = this.project_detail;
                // this.assignedProjectMembers = this.project_detail.teammates
            },
    
            task_category(oldValue, newValue) {
                // console.log(this.task_category.labels)
                this.categories = this.task_category.labels;
                this.categories_options = this.task_category.labels;
                this.tasks = this.task_category.tasks;
            },
    
            sub_task_list(oldValue, newValue) {
                this.sub_tasks = this.sub_task_list;
            },
    
            client_by_project(oldValue, newValue) {},
            vendor_by_project(oldValue, newValue) {},
            comments(oldValue, newValue) {},
    
            task_details_for_update(oldValue, newValue) {
                this.task_details.id = this.task_details_for_update.id
                this.task_details.title = this.task_details_for_update.title
                this.task_details.description = this.task_details_for_update.description
                this.task_details.start_date = this.task_details_for_update.start_date
                this.task_details.end_date = this.task_details_for_update.end_date
                this.task_details.teammates = this.task_details_for_update.teammates
            },
    
            assignedProjectMembers: {
                handler: function (newVal, oldVal) {
                    this.storeAssignedProjectMembers(newVal);
                },
                deep: true
            },
    
            assignedMembers: {
                handler: function (newVal, oldVal) {
                    this.storeAssignedMembers(newVal);
                },
                deep: true
            }
        },
    
        methods: {
            // openModal(){
            //     console.log('afaf')
            //     const taskId = this.$route.query.task;
            //     if (taskId) {
            //         this.$nextTick(() => {
            //         this.$bvModal.show('modal-xl')
            //     })   
            //     } 
            // },

            openModal() {
                this.$nextTick(() => {
                    this.$refs.taskmodal.show(); // Use $refs to control modal
                });
            },

            assignMemberModal(sub, type) {
                console.log(sub)
                this.crud_title = 'Assign Member'
    
                if (sub !== null) {
                    this.subtask_id = sub.id
                    this.subtask_teammates = sub.teammates
                } else {
                    this.subtask_id = null
                }
    
                this.task_id = this.task_details.id
                this.type = type
                if (type === 'from_subtask') {
                    this.$store.dispatch("project/fetchTaskMemberList", this.task_id);
                } else {
                    this.$store.dispatch("project/fetchProjectMemberList", this.$route.params.id);
                }
            },

            truncateText(text, maxLength) {
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + '...';
                } else {
                    return text;
                }
            },
    
            getLabelName(name) {
                this.label_name = name
                this.new_label_name = name
            },
    
            handleLabelOk(bvModalEvent) {
                this.handleLabelSubmit()
            },
    
            handleLabelSubmit() {
                this.$store.dispatch("task/editTaskLabel", {
                    old_label: this.label_name,
                    new_label: this.new_label_name,
                    project_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.label_name = ''
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                    this.label_name = ''
                })
    
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-center-edit-label')
                })
            },
    
            deleteTaskLabel() {
                this.$store.dispatch("task/deleteLabel", {
                    name: this.label_name,
                    project_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.label_name = '';
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                        this.label_name = '';
                    }
                })

                this.$nextTick(() => {
                    this.$bvModal.hide('modal-center-delete-label')
                })
            },
    
            // assignMemberModal(id) {
            //     console.log(sub)
            //     this.crud_title = 'Assign Member'
            //     this.subtask_id = id
            //     this.task_id = this.task_details.id
            //     if (id) {
            //         this.$store.dispatch("project/fetchTaskMemberList", this.task_id);
            //     } else {
            //         this.$store.dispatch("project/fetchProjectMemberList", this.$route.params.id);
            //     }
            // },
    
            storeAssignedMembers(assignedMembers) {
                const latestId = assignedMembers[assignedMembers.length - 1].id;
    
                this.$store.dispatch("task/assignEmployee", {
                    task_id: this.task_id,
                    subtask_id: this.subtask_id,
                    member_id: latestId
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.assignedMembers = '';
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                        this.assignedMembers = '';
                    }
                })
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-1')
                })
            },
    
            storeAssignedProjectMembers(assignedProjectMembers) {
                const latestId = assignedProjectMembers[assignedProjectMembers.length - 1].id;
    
                this.$store.dispatch("project/assignProjectEmployee", {
                    project_id: this.$route.params.id,
                    member_id: latestId
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.assignedProjectMembers = '';
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                        this.assignedProjectMembers = '';
                    }
                })

                this.$nextTick(() => {
                    this.$bvModal.hide('modal-scrollable')
                })
            },
    
            handleAttachOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleAttahmentSubmit()
            },
    
            handleAttahmentSubmit() {
                this.add_attachment.project_id = this.$route.params.id
                this.$store.dispatch("project/addProjectAttachment", {
                    project_id: this.$route.params.id,
                    attachment: this.add_attachment
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.add_attachment.project_attachment = null
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
    
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-scrollable-add-document')
                })
            },
    
            uploadAttachFile(e) {
                this.add_attachment.project_attachment = e.target.files[0]
            },
    
            storeAssignedVendor(assignedVendor) {
                const latestId = assignedVendor[assignedVendor.length - 1].id;
                // alert(latestId)
                this.$store.dispatch("project/ProjectAddVendor", {
                    project_id: this.$route.params.id,
                    vendor_id: latestId
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.assignedVendor = '';
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
    
                })
                // this.$nextTick(() => {
                //     this.$bvModal.hide('modal-scrollable-vendor')
                // })
            },
    
            storeAssignedClient(assignedClient) {
                const latestId = assignedClient[assignedClient.length - 1].id;
                // alert(latestId)
                this.$store.dispatch("project/ProjectAddClient", {
                    project_id: this.$route.params.id,
                    client_id: latestId
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.assignedClient = '';
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
    
                })
                // this.$nextTick(() => {
                //     this.$bvModal.hide('modal-scrollable')
                // })
            },
    
            random() {
                return random
            },
    
            budgetDetails() {
                this.$store.dispatch("project/fetchBudgetByProject", this.$route.params.id)
            },
    
            QuotationDetails() {
                this.$store.dispatch("project/fetchQuotationByProject", this.$route.params.id)
            },
    
            InvoiceDetails() {
                this.$store.dispatch("project/fetchInvoiceByProject", this.$route.params.id)
            },
    
            RequisitionDetails() {
                this.$store.dispatch("project/fetchRequisitionByProject", this.$route.params.id)
            },
    
            PurchaseOrderDetails() {
                this.$store.dispatch("project/fetchPurchaseOrderByProject", this.$route.params.id)
            },
    
            BillDetails() {
                this.$store.dispatch("project/fetchBillByProject", this.$route.params.id)
            },
    
            RFPDetails() {
                this.$store.dispatch("project/fetchRFPByProject", this.$route.params.id)
            },
    
            myFunction(id) {
                // Get the checkbox
                var checkBox = document.getElementById("myCheck");
                // Get the output text
                var text = document.getElementById("text");
    
                this.$store.dispatch("task/toggleSubTaskCompletefromProject", {
                    id: id,
                    parent: this.task_detail.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
    
                // If the checkbox is checked, display the output text
                if (checkBox.checked == true) {
                    text.style.display = "block";
                } else {
                    text.style.display = "none";
                }
            },
    
            editTaskDetails(task) {
                this.isEditable = true
                this.detail.id = task.id
                this.detail.title = task.title
                this.detail.description = task.description
                this.detail.start_date = task.start_date
                this.detail.end_date = task.end_date
                this.detail.teammates = task.teammates
            },
    
            cancelEditable() {
                this.isEditable = false
                // this.$store.dispatch("task/fetchTaskDetails", this.task_detail.id)
            },
    
            onSubmit() {
                if (this.detail.start_date !== null) {
                    this.detail.start_date = moment(String(this.detail.start_date)).format("YYYY-MM-DD");
                }
                if (this.detail.end_date !== null) {
                    this.detail.end_date = moment(String(this.detail.end_date)).format("YYYY-MM-DD");
                }
    
                this.$store.dispatch("task/updateTask", {
                    detail: this.detail,
                    project_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.isEditable = false
                        this.task_details.id = response.data.id
                        this.task_details.title = response.data.title
                        this.task_details.description = response.data.description
                        this.task_details.start_date = response.data.start_date
                        this.task_details.end_date = response.data.end_date
                        this.task_details.teammates = response.data.teammates
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            deleteAttach(id) {
                this.$store.dispatch("project/deleteAttachmentFromProjects", {
                    id: id,
                    project_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.delete_value = null
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            deletetAllocation(id) {
                this.$store.dispatch("project/deleteTeammateFromProjects", {
                    id: id,
                    detail_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.delete_value = null
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            deletetAllocationClient(id) {
                this.$store.dispatch("project/deleteClientFromProjects", {
                    id: id,
                    detail_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.delete_value = null
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            deletetAllocationVendor(id) {
                this.$store.dispatch("project/deleteVendorFromProjects", {
                    id: id,
                    detail_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.delete_value = null
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            deleteAssignedMembers(id) {
                this.$store.dispatch("task/deleteAssignedMember", {
                    id: id,
                    task: this.task_details.id
                }).then(response => {
                    if (response.code == 200) {
                        this.task_detail = null
                        this.task_details.teammates = this.task_details_for_update.teammates
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        // this.$bvModal.hide('modal-xl')
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
                // this.$nextTick(() => {
                //     this.$bvModal.hide('modal-1')
                // })
            },
    
            getTaskTitle(id) {
                this.$store.dispatch("task/fetchTaskDetails", id).then(result => {
                    if (result.code == 200) {
                        this.task_details.id = result.data.id ? result.data.id : null
                        this.task_details.title = result.data.title ? result.data.title : null
                        this.task_details.description = result.data.description ? result.data.description : null
                        this.task_details.start_date = result.data.start_date ? result.data.start_date : null
                        this.task_details.end_date = result.data.end_date ? result.data.end_date : null
                        this.task_details.teammates = result.data.teammates ? result.data.teammates : []
                    }
                })
    
                this.$store.dispatch("task/fetchSubTaskList", id)
                this.$store.dispatch("task/fetchComments", id)
            },
    
            resetBoroModal() {
                // this.task_details.id = null
                // this.task_details.title = null
                // this.task_details.description = null
                // this.task_details.start_date = null
                // this.task_details.end_date = null
                // this.task_details.teammates = []
            },
    
            afterMove(id, status) {
                // alert(id)
                this.kanbanShow = false
    
                this.$store.dispatch("task/updateTaskLabel", {
                    label: status,
                    project: this.project.id,
                    id: id
                }).then(response => {
                    if (response.code == 200) {
                        this.tasks = this.task_category.tasks;
                        this.kanbanShow = true
                        // console.log(this.tasks)
                    } else {
                        this.kanbanShow = true
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            resetTaskModal() {
                this.task.title = ''
                this.task.label_name = ''
            },
    
            handleTaskOk(bvModalEvent) {
                // Prevent modal from closing
                bvModalEvent.preventDefault()
                // Trigger submit handler
                this.handleTaskSubmit()
            },
    
            handleTaskSubmit() {
                this.$store.dispatch("task/addTask", this.task).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.task.title = ''
                        this.task.label_name = ''
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
    
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing-add-task')
                })
            },
    
            addTask(label, title) {
                this.$store.dispatch("task/addTask", {
                    title: title,
                    project: this.project.id,
                    label_name: label
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.name = ''
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            toggleTaskPriority(id, priority) {
                this.$store.dispatch("task/toggleTaskPriority", {
                    id: id,
                    priority: priority,
                    project: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            addStage() {
                if (this.name != '') {
                    this.$store.dispatch("task/addTaskCategory", {
                        name: this.name,
                        project: this.$route.params.id
                    }).then(response => {
                        if (response.code == 200) {
                            Swal.fire({
                                title: "Success!",
                                text: response.response,
                                icon: "success"
                            });
                            this.name = ''
                        } else {
                            Swal.fire({
                                title: "Error!",
                                text: response.response,
                                icon: "error"
                            });
                        }
                    })
                } else {
                    alert('You must enter label name!')
                }
            },
    
            assignProjectMember() {
                this.assignedProjectMembers.map(item => this.project_member.push({
                    project_id: this.$route.params.id,
                    member_id: item.id,
    
                }));
    
                this.$store.dispatch("project/assignProjectEmployee", {
                    member: this.project_member,
                    id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.assignedProjectMembers = [],
                        this.project_member = []
                        // this.$bvModal.hide('modal-1-assign')
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            createSubTask() {
                if (this.sub.title != '') {
                    this.$store.dispatch("task/addSubTask", {
                        title: this.sub.title,
                        description: this.sub.description,
                        project: this.task_details.project,
                        parent: this.task_details.id
                    }).then(response => {
                        if (response.code == 200) {
                            Swal.fire({
                                title: "Success!",
                                text: response.response,
                                icon: "success"
                            });
                            this.name = ''
                        } else {
                            Swal.fire({
                                title: "Error!",
                                text: response.response,
                                icon: "error"
                            });
                        }
                    })
                } else {
                    alert('You must enter title!')
                }

                this.sub.title = ''
                this.sub.description = ''
                this.subTaskCreate = false
            },
    
            editSubTask(sub) {
                this.crud_title = 'Edit'
                this.edit_sub.id = sub.id
                this.edit_sub.title = sub.title
                this.edit_sub.description = sub.description
                this.edit_sub.end_date = sub.end_date
            },
    
            updateSubTask() {
                if (this.edit_sub.end_date !== null) {
                    this.edit_sub.end_date = moment(String(this.edit_sub.end_date)).format("YYYY-MM-DD");
                }
                if (this.edit_sub.end_date !== null) {
                    this.edit_sub.end_date = moment(String(this.edit_sub.end_date)).format("YYYY-MM-DD");
                }
    
                this.$store.dispatch("task/updateSubTask", {
                    sub: this.edit_sub,
                    parent: this.task_details.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.edit_sub.id = null
                        this.edit_sub.title = ''
                        this.edit_sub.description = ''
                        this.$bvModal.hide('modal-1')
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            deleteSubTask(id) {
                this.delete_value = id
            },
    
            handleDeleteOk() {
                this.$store.dispatch("task/deleteSubTask", {
                    id: this.delete_value,
                    parent: this.task_details.id,
                    project_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.delete_value = null
                        // this.$bvModal.hide('modal-xl')
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },

            deleteMainTask(id) {
                this.delete_task_value = id
            },
    
            handleTaskDeleteOk() {
                this.$store.dispatch("task/deleteTask", {
                    id: this.delete_task_value,
                    project_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.delete_value = null
                        this.$bvModal.hide('modal-xl')
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            addComment() {
                if(!this.comment.comment || !this.comment.comment.trim()){
                    return
                }

                this.$store.dispatch("task/addComment", {
                    task_id: this.task_details.id,
                    comment: this.comment.comment
                }).then(response => {
                    if (response.code == 200) {
                        // Swal.fire({
                        //     title: "Success!",
                        //     text: response.response,
                        //     icon: "success"
                        // });
                        this.comment.comment = ''
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            handleStatusOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleStatusSubmit()
            },
    
            handleStatusSubmit() {
                this.$store.dispatch("project/updateProjectPriority", {
                    id: this.$route.params.id,
                    status: this.selectedStatus
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.delete_value = null
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
    
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing-status')
                })
            },
    
            toBudgetRequest() {
                this.$router.push('/finance-and-accounts/budget/request-for-budget/' + this.$route.params.id)
            },

            toQuotationsRequest() {
                this.$router.push('/finance-and-accounts/finance/quotation/create/' + this.$route.params.id)
            },

            toInvoiceRequest() {
                this.$router.push('/finance-and-accounts/finance/invoice/create/' + this.$route.params.id)
            },

            toRequisitionRequest() {
                this.$router.push('/finance-and-accounts/account/requisition/create/' + this.$route.params.id)
            },

            toPurchaseOrderRequest() {
                this.$router.push('/finance-and-accounts/finance/purchase-order/create/' + this.$route.params.id)
            },

            toBillRequest() {
                this.$router.push('/finance-and-accounts/finance/bill/create/' + this.$route.params.id)
            },

            toRFPRequest() {
                this.$router.push('/procurement/rfp/create/' + this.$route.params.id)
            },

            convertUrls(text) {
                if (text === null) {
                    return '';
                }
                const urlPattern = /(https?:\/\/[^\s<]+)/g;
                return text.replace(urlPattern, (url) => {
                    return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
                });
            }
        }
    };
    </script>
    
    <style src="vue-multiselect/dist/vue-multiselect.min.css"></style><style scoped>
    .title-button-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .drag-inner-list {
        min-height: 50px;
        padding: 20px;
        max-height: 500px !important;
        overflow-y: scroll;
    }
    
    .text-style {
        margin-bottom: 2px;
    }
    
    .upper-button-container {
        display: flex;
        position: relative;
        transition: 0.6s;
        cursor: pointer;
        width: fit-content;
        gap: 12px;
        cursor: pointer;
    }
    
    .iconStyle {
        width: 36px;
        height: 36px;
        background-color: #222131;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 20px;
        transition: all 0.3s ease-in-out;
        position: relative;
        z-index: 1;
    }
    
    .description-container {
        position: absolute;
        width: 0;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: width 1s ease, opacity 0.6s ease, visibility 0s;
        z-index: 99;
        display: flex;
        align-items: center;
        background-color: #222131;
        border-radius: 24px;
        color: white;
    }
    
    .description-inner {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    
    .iconStyle:hover+.description-container,
    .description-container:hover {
        width: 100%;
        opacity: 1;
        visibility: visible;
    }
    
    .iconStyle:hover+.description-container {
        transition-delay: 0.2s;
    }
    
    .detail-container {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    
    .button-bill {
        display: flex;
        gap: 10px;
    }
    
    .dropdown {
        position: relative;
    }
    
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 10px;
        z-index: 99;
        right: 2px;
    
    }
    
    .dropdown:hover .dropdown-content {
        display: flex;
        flex-direction: column;
        gap: 2px;
        font-weight: 300;
    }
    
    .task-board-full {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 10px;
    }
    
    .task-board-sub-task {
        width: 60%;
    }
    
    .task-board-comment-width {
        width: 40%;
    }
    
    .task-board-task-scroll {
        position: relative;
        height: 84vh;
    }
    
    .task-board-scroll {
        position: relative;
        height: 84vh;
    }
    
    .task-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .task-board-comments {
        display: flex;
        flex-direction: column;
        /* gap: 15px; */
        height: 93%;
        overflow-y: auto;
    }
    
    .task-board-comment-box {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    
    .badge-style {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        background-color: #2c323f;
        padding: 0.375rem 1.75rem;
        color: white;
    
    }
    
    .badge-style:hover {
        background-color: #ff595d;
    }
    
    .chatbox {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px 10px;
    }
    
    .chat {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        word-break: break-all;
    }
    
    .avatar img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        border: solid 2px white;
    }
    
    .message {
        flex-grow: 1;
    }
    
    .msg {
        background: #fff0f0;
        width: 100%;
        /* Ensures message takes full width */
        padding: 10px;
        border-radius: 10px;
        position: relative;
        font-size: 13px;
        word-break: break-all;
    }
    
    /* height */
    ::-webkit-scrollbar {
        height: 5px;
        width: 3px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    
    @media screen and (max-width: 1279px) {
        .detail-container {
            display: flex;
            flex-direction: column;
            gap: 25px;
        }
    }
    
    /* @media screen and (max-width: 991px) {
        .task-board-full {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 40px;
        }
    
        .task-board-sub-task {
            width: 100%;
        }
    
        .task-board-comment-width {
            width: 100%;
        }
    
        .task-board-task-scroll {
            position: relative;
            height: 100%;
        }
    
        .task-board-scroll {
            position: relative;
            height: 70vh;
        }
    
        .task-container {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            gap: 20px;
        }
    
        .task-board-comments {
            height: 88%;
        }
    } */
    
    @media screen and (max-width: 767px) {
        .title-button-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: space-between;
            align-items: start;
        }
    
        .text-style {
            margin-bottom: 5px;
        }
    }
    
    .img-50 {
        width: 50px !important;
        height: 50px !important;
    }
    
    .img-30 {
        width: 30px !important;
        height: 30px !important;
    }
    
    .img-20 {
        width: 20px !important;
        height: 20px !important;
    }
    
    .ck-editor-container {
        overflow: hidden !important;
        word-wrap: break-word;
    }
    
    .ck-editor__editable {
        word-break: break-all;
    }
    </style>
    