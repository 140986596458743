import { render, staticRenderFns } from "./create-leave.vue?vue&type=template&id=38a31b00&scoped=true&"
import script from "./create-leave.vue?vue&type=script&lang=js&"
export * from "./create-leave.vue?vue&type=script&lang=js&"
import style0 from "./create-leave.vue?vue&type=style&index=0&id=38a31b00&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a31b00",
  null
  
)

export default component.exports