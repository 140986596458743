<template>
<div>
    <Breadcrumbs main="CRM" title="Leads" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Lead</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row class="d-flex justify-content-center align-items-center">
                            <b-col xl="6" class="d-flex flex-wrap" style="gap: 20px">
                                <b-input-group style="width: 340px;">
                                    <b-form-input v-model="filter" placeholder="Type to Search" style="box-shadow: none;"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                    <feather type="filter"></feather>
                                    <h7>Filter</h7>
                                </div> 

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="selectConnected || selectedLabel">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="3" label="Per page" class="mb-0 datatable-select w-100">
                                    <b-form-select v-model="perPage" :options="pageOptions" style="width: 100%;"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <b-form-select v-model="selectConnected" :options="formattedOptions"></b-form-select>
                                    </div>
                                    <!-- <div class="grid-item">
                                        <b-form-select v-model="selectedLabel" :options="formattedLabels"></b-form-select>
                                    </div> -->
                                </div>
                            </div>
                        </div>

                        <div class="lead-container" style="margin-top: 22px;">
                            <div class="table-width">
                                <div v-if="!loading && !error">
                                    <div class="table-responsive datatable-vue">
                                        <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                            <template #cell(index)="data">
                                                {{ (currentPage - 1) * perPage + data.index + 1 }}
                                            </template>

                                            <template #cell(address)="data">
                                                <span style="display: flex; flex-direction: column; gap: 8px;">
                                                    <small><span style="font-size: medium;">
                                                            <b-badge pill variant="dark">{{ data.item.company }}</b-badge>
                                                        </span></small>
                                                    <span>{{ data.item.address }}</span>
                                                </span>
                                            </template>

                                            <template #cell(label)="data">
                                                <div v-if="data.item.label !== null && data.item.label.length > 0" @click="addLabeltoLead(data.item)" v-b-modal.modal-prevent-closing-label>
                                                    <div v-for="label in data.item.label" :key="label.id">

                                                        <b-badge pill variant="success">{{ label.name }}</b-badge>
                                                    </div>
                                                </div>
                                                <div v-else v-b-modal.modal-prevent-closing-label @click="addLabeltoLead(data.item)">
                                                    <b-badge pill variant="secondary">Add Label</b-badge>
                                                </div>
                                            </template>

                                            <template #cell(contacted)="row">
                                                <span v-if="row.value == true">
                                                    <b-badge variant="success">Yes</b-badge>
                                                </span>
                                                <span  v-if="row.value == false ">
                                                    <b-badge variant="danger">No</b-badge>
                                                </span>
                                            </template>

                                            <template #cell(action)="data">
                                                <span style="display: flex; gap: 12px;">
                                                    <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteLead(data.item.id)" title="Delete"></feather>
                                                    <feather @click="editItem(data.item)" type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                                </span>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="loader-box">
                                        <div class="loader-10"></div>
                                    </div>
                                </div>

                                <b-col class="my-1 p-0">
                                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                </b-col>
                            </div>

                            <div class="label-width">
                                <div class="label-small" v-if="!loading && !error">
                                    <h6>Labels:</h6>
                                    <div style="display: flex; gap: 10px;">
                                        <input class="form-control" type="text" placeholder="Enter label" v-model="label">
                                        <button class="btn btn-success" @click="addLabel()">Add</button>
                                    </div>
                                    <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); margin-top: 8px; gap: 8px;">
                                        <div v-for="label in labels" :key="label.index" style="width: 100%;">
                                            <span style="font-size: medium; width: 100%;">
                                                <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="dark">
                                                    <span style="width: 80%; padding: 4px; text-align: left;" @click="selectLabel(label.id)"> {{ label.name }} </span>
                                                    <span @click="deleteLabel(label.id)" style="width: 20%; padding: 4px;">🗙</span>
                                                </b-badge>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="label-small" v-else>
                                    <div class="loader-box">
                                        <div class="loader-10"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Create Lead -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Create Lead" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <!-- <b-form-group label="Name" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="item.first_name" placeholder="Enter name"></b-form-input>
            </b-form-group> -->
            <b-form-group label-for="name-input">
                <template #label>
                    Name <span style="color: red;">*</span>
                </template>
                <b-form-input 
                    id="name-input" 
                    v-model="item.first_name" 
                    placeholder="Enter name">
                </b-form-input>
            </b-form-group>
            <b-form-group label="Email" label-for="name-input" invalid-feedback="Email is required">
                <b-form-input id="name-input" v-model="item.email" placeholder="Enter email address"></b-form-input>
            </b-form-group>
            <b-form-group label="Phone No" label-for="name-input" invalid-feedback="Phone no is required">
                <b-form-input id="name-input" v-model="item.phone" placeholder="Enter phone number"></b-form-input>
            </b-form-group>
            <!-- <b-form-group label="Company" label-for="name-input" invalid-feedback="Company is required">
                <b-form-input id="name-input" v-model="item.company" placeholder="Enter company name"></b-form-input>
            </b-form-group> -->
            <b-form-group label-for="name-input">
                <template #label>
                    Company <span style="color: red;">*</span>
                </template>
                <b-form-input 
                    id="name-input" 
                    v-model="item.company" 
                    placeholder="Enter company name">
                </b-form-input>
            </b-form-group>
            <b-form-group label="Address" label-for="name-input" invalid-feedback="Address is required">
                <b-form-input id="name-input" v-model="item.address" placeholder="Enter address"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Edit Lead -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Lead" @show="resetModal" @hidden="resetModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <!-- <b-form-group label="Name" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="edit_item.first_name" placeholder="Enter name"></b-form-input>
            </b-form-group> -->
            <b-form-group label-for="name-input">
                <template #label>
                    Name <span style="color: red;">*</span>
                </template>
                <b-form-input 
                    id="name-input" 
                    v-model="edit_item.first_name" 
                    placeholder="Enter name">
                </b-form-input>
            </b-form-group>
            <b-form-group label="Email" label-for="name-input" invalid-feedback="Email is required">
                <b-form-input id="name-input" v-model="edit_item.email" placeholder="Enter email address"></b-form-input>
            </b-form-group>
            <b-form-group label="Phone No" label-for="name-input" invalid-feedback="Phone no is required">
                <b-form-input id="name-input" v-model="edit_item.phone" placeholder="Enter phone number"></b-form-input>
            </b-form-group>
            <!-- <b-form-group label="Company" label-for="name-input" invalid-feedback="Company is required">
                <b-form-input id="name-input" v-model="edit_item.company" placeholder="Enter company name"></b-form-input>
            </b-form-group> -->
            <b-form-group label-for="name-input">
                <template #label>
                    Company <span style="color: red;">*</span>
                </template>
                <b-form-input 
                    id="name-input" 
                    v-model="edit_item.company" 
                    placeholder="Enter company name">
                </b-form-input>
            </b-form-group>
            <b-form-group label="Address" label-for="name-input" invalid-feedback="Address is required">
                <b-form-input id="name-input" v-model="edit_item.address" placeholder="Enter address"></b-form-input>
            </b-form-group>
            <b-form-group label="Contacted" label-for="name-input" invalid-feedback="Address is required">
                <b-form-select v-model="edit_item.contacted" :options="formattedContact"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this lead?</div>
    </b-modal>

    <!-- Edit Lead -->
    <b-modal id="modal-prevent-closing-label" ref="modal" title="Add Label" @show="resetLabelModal" hide-footer @hidden="resetLabelModal" @ok="handleLabelOk">
        <h6>Labels</h6>

        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="label in this.already_assigned_labels" :key="label.id" v-b-tooltip.hover @click="deletetLeads(label.id)" title="Delete">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ label.name}}</h7>
                    <b-icon icon="x" style="color: white;"></b-icon>
                </div>
            </div>
        </div>

        <form ref="form" @submit.stop.prevent="handleLabelSubmit">
            <multiselect v-model="assignedLabels" tag-placeholder="Add member" placeholder="Search or add a label" label="name" :max="labels.length" track-by="id" :options="labels" :multiple="true" :taggable="true"></multiselect>
        </form>
    </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },

    data() {
        return {
            tablefields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'first_name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'phone',
                    label: 'Phone No',
                    sortable: true
                },
                {
                    key: 'address',
                    label: 'Address',
                    sortable: true
                },
                {
                    key: 'label',
                    label: 'Label',
                    sortable: true
                },
                {
                    key: 'contacted',
                    label: 'Contacted',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            item: {
                first_name: null,
                email: '',
                phone: '',
                company: null,
                address: ''
            },

            edit_item: {
                id: null,
                first_name: null,
                email: '',
                phone: '',
                company: null,
                address: '',
                contacted:null,
            },

            assignedLabels: [],

            label: '',
            selected: null,

            lead_id: null,
            already_assigned_labels: [],

            delete_value: null,

            filter: null,

            filterShow: false,
            selectConnected: '',
            selectedLabel: null,

            connected_list: [
                {
                    id: 1,
                    name: 'Yes',
                    value: 'True'
                },
                {
                    id: 2,
                    name: 'No',
                    value: 'False'
                }
            ],

            initialLoad: true,

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            isModifyingDate: false
        };
    },

    computed: {
        ...mapState({
            items: state => state.lead.lead,
            loading: state => state.lead.submitLoading,
            error: state => state.lead.submitError,
            count: state => state.lead.lead_count,
            labels: state => state.lead.label
        }),

        formattedContact() {
            return [
                {
                    value: '',
                    text: 'Contacted',
                    disabled: true
                },
                {
                    value: true,
                    text: 'Yes'
                },
                {
                    value: false,
                    text: 'No'
                }
            ];
        },

        formattedOptions() {
            return [
                {
                    value: '',
                    text: 'Select contact status',
                    disabled: true
                },
                ...this.connected_list.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        },

        formattedLabels() {
            return [
                {
                    value: '',
                    text: 'Select Labels',
                    disabled: true
                },
                ...this.labels.map(status => ({
                    value: status.name,
                    text: status.name
                }))
            ];
        }
    },

    mounted() {
        this.$store.dispatch("lead/fetchLabel");

        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {
               
                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitial();
            } else {
                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitial();
            }
        }
    },

    watch: {
        assignedLabels: {
            handler: function (newVal, oldVal) {
                this.storeAssignedLabels(newVal);
            },
            deep: true
        },

        filter(newVal, oldVal) {
            if(!this.initialLoad){
                this.$store.dispatch("lead/fetchLead", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.filter,
                    contacted: this.selectConnected,
                    label: this.selectedLabel
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                }); 
            } 
        },

        selectedLabel(newVal, oldVal) {
            if(!this.initialLoad){
                this.$store.dispatch("lead/fetchLead", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.filter,
                    contacted: this.selectConnected,
                    label: newVal
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            } 
        },

        selectConnected(newVal, oldVal) {
            if(!this.initialLoad){
                this.$store.dispatch("lead/fetchLead", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.filter,
                    contacted: newVal,
                    label: this.selectedLabel
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }  
        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/crm/leads/list',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("lead/fetchLead", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    search_term: this.filter,
                    contacted: this.selectConnected,
                    label:this.selectedLabel
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/crm/leads/list',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("lead/fetchLead", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    search_term: this.filter,
                    contacted: this.selectConnected,
                    label:this.selectedLabel
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }
        }
    },

    methods: {
        fetchInitial() {
            this.$store.dispatch("lead/fetchLead", {
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    search_term: this.filter,
                    contacted: this.selectConnected,
                    label:this.selectedLabel
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false;
            });
        },

        clearFilter() {
            this.filterShow = false,
            this.filter = null,
            this.selectConnected ='',
            this.selectedLabel = null
        },

        selectLabel(id){
            this.selectedLabel = id
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        storeAssignedLabels(assignedLabels) {
            const latestId = assignedLabels[assignedLabels.length - 1].id;

            this.$store.dispatch("lead/addLabeltoLead", {
                lead: this.lead_id,
                label_id: latestId,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                labelAdd_delete: true,
                search_term: this.filter,
                contacted: this.selectConnected,
                label:this.selectedLabel
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedLabels = [];

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-label')
            })
        },

        addLabeltoLead(type) {
            this.lead_id = type.id
            this.already_assigned_labels = type.label
        },

        deletetLeads(id) {
            this.$store.dispatch("lead/deleteLabelfromLead", {
                leadId: this.lead_id,
                label_id: id,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter,
                contacted: this.selectConnected,
                label:this.selectedLabel,
                labelAdd_delete: true,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedLabels = [];

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-label')
            })
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        addLabel() {
            if (this.label !== '') {
                this.$store.dispatch("lead/addLabel", this.label).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.label = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }
        },

        resetModal() {
            this.item.first_name = null
            this.item.email = ''
            this.item.phone = ''
            this.item.company = null
            this.item.address = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleSubmit() {
            if(this.item.first_name && this.item.company) {
                this.$store.dispatch("lead/addLead", {
                    item: this.item,
                    perPage: this.$route.query.perPage,
                    page: this.$route.query.page,
                    search_term: this.filter,
                    contacted: this.selectConnected,
                    label:this.selectedLabel
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.item.first_name = null
                        this.item.email = ''
                        this.item.phone = ''
                        this.item.company = null
                        this.item.address = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            } else {
                Swal.fire({
                    title: "Error!",
                    text: "You must enter Lead's Name & Company",
                    icon: "error"
                });
            }
        },

        editItem(item) {
            this.edit_item.id = item.id
            this.edit_item.first_name = item.first_name
            this.edit_item.email = item.email
            this.edit_item.phone = item.phone
            this.edit_item.company = item.company
            this.edit_item.address = item.address
            this.edit_item.contacted = item.contacted
        },

        resetEditModal() {
            this.edit_item.id = null
            this.edit_item.first_name = null
            this.edit_item.email = ''
            this.edit_item.phone = ''
            this.edit_item.company = null
            this.edit_item.address = ''
            this.edit_item.contacted = null
        },

        handleEditSubmit() {
            if(this.edit_item.first_name && this.edit_item.company) {
                this.$store.dispatch("lead/updateLead", {
                    item: this.edit_item,
                    perPage: this.$route.query.perPage,
                    page: this.$route.query.page,
                    search_term: this.filter,
                    contacted: this.selectConnected,
                    label:this.selectedLabel
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.edit_item.id = null
                        this.edit_item.first_name = null
                        this.edit_item.email = ''
                        this.edit_item.phone = ''
                        this.edit_item.company = null
                        this.edit_item.address = ''
                        this.edit_item.contacted = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing-edit')
                })
            } else {
                Swal.fire({
                    title: "Error!",
                    text: "You must enter Lead's Name & Company",
                    icon: "error"
                });
            }
        },

        deleteLead(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("lead/deleteLead", {
                item: this.delete_value,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page,
                search_term: this.filter,
                contacted: this.selectConnected,
                label:this.selectedLabel
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        deleteLabel(id) {
            this.$store.dispatch("lead/deleteLabel", id).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;
}

.badge-style:hover {
    background-color: #ff595d;
}

.lead-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.table-width {
    width: 100%;
}

.label-width {
    width: 100%;
}

.label-small {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .label-small {
        width: 65%;
    }
}

@media screen and (min-width: 1100px) {
    .label-small {
        width: 50%;
    }
}

@media screen and (min-width: 1536px) {
    .lead-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .table-width {
        width: 75%;
    }

    .label-width {
        width: 25%;
    }

    .label-small {
        width: 100%;
    }
}
</style>
