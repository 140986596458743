<template>
    <div>
        <Breadcrumbs main="Procurement" title="RFP Create" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Title <span style="color: red;">*</span></label>
                                            <input class="form-control" type="text" placeholder="Enter rfp title" v-model="rfp.title">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Category <span style="color: red;">*</span></label>
                                            <select class="form-control digits" v-model="rfp.rfp_category">
                                                <option v-for="(item, index) in items" :key="index" :value="item.id">{{ item.title }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Project</label>
                                            <select class="form-control digits" v-model="rfp.project">
                                                <option v-for="(project, index) in projects" :key="index" :value="project.id">{{ project.title }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Status</label>
                                            <select class="form-control digits" v-model="rfp.status">
                                                <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Deadline</label>
                                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="rfp.deadline"></datepicker>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Upload Attachment</label>
                                            <b-form-file
                                                v-model="rfp.rfp_attachment"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                                @change="uploadFile"
                                            ></b-form-file>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Short Description <span style="color: red;">*</span></label>
                                            <textarea 
                                                class="form-control" 
                                                id="textarea-auto-height"
                                                placeholder="Enter short description..." 
                                                rows="5"  
                                                max-rows="8" 
                                                v-model="rfp.details"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mb-0">
                                            <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                            <router-link to="/procurement/rfp/list">
                                                <button class="btn btn-danger" href="#">Go Back</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data(){
        return{
            rfp: {
                title: '',
                project: null,
                status: 'APPROVED',
                priority: 'HIGH',
                rfp_category: null,
                deadline: null,
                details: '',
                rfp_attachment: null
            },

            statuses: ['APPROVED', 'WORKING', 'COMPLETED']
        };
    },
    
    computed: {
        ...mapState({
            items: state => state.rfp.rfp_category,
            projects: state => state.project.project,
        })
    },

    mounted() {
        this.$store.dispatch("rfp/fetchRFPCategory");
        this.$store.dispatch("project/fetchProject");

        if (this.$route.params.project == 'new') {
            this.rfp.project = ''
        } else {
            this.rfp.project = this.$route.params.project
        }
    },

    methods: {
        uploadFile(e){
            this.rfp.rfp_attachment=e.target.files[0]
        },

        onSubmit() {
            if(this.rfp.deadline !== null) {
                this.rfp.deadline = moment(String(this.rfp.deadline)).format("YYYY-MM-DD");
            }

            this.rfp.title = this.rfp.title.trim() ? this.rfp.title : null;
            this.rfp.details = this.rfp.details.trim() ? this.rfp.details : null;

            if(this.rfp.title && this.rfp.details && this.rfp.rfp_category) {
                this.$store.dispatch("rfp/addRFP", this.rfp).then(response => {
                    if(response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.rfp.title = '',
                        this.rfp.details = '',
                        this.rfp.rfp_category = null,
                        this.rfp.deadline = null,
                        this.rfp.priority = 'HIGH',
                        this.rfp.status = 'APPROVED',
                        this.rfp.rfp_attachment = null
                    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            } else {
                this.rfp.title = '',
                this.rfp.details = '',
                this.rfp.rfp_category = null,
                this.rfp.deadline = null,
                this.rfp.priority = 'HIGH',
                this.rfp.status = 'APPROVED',
                this.rfp.rfp_attachment = null

                Swal.fire({
                    title: "Error!",
                    text: 'You must enter all the mandatory fields (*)',
                    icon: "error"
                });
            }
        }
    }
};
</script>
