<template>
    <div>
        <!-- page-wrapper Start-->
        <div class="container-fluid p-0">
            <div class="login-card">
                <px-card>
                    <div style="padding: 28px;">
                        <div class="theme-form">
                            <div class="vendor-width">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>First Name <span style="color: red;">*</span></label>
                                            <input class="form-control" type="text" placeholder="Enter first name" v-model="candidate.first_name">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Last Name <span style="color: red;">*</span></label>
                                            <input class="form-control" type="text" placeholder="Enter last name" v-model="candidate.last_name">
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div>
                                <div class="form-group">
                                    <label>Email <span style="color: red;">*</span></label>
                                    <input class="form-control" type="text" placeholder="Enter your email address" v-model="candidate.email">
                                </div>
                            </div>
    
                            <div>
                                <div class="form-group">
                                    <label>Phone Number <span style="color: red;">*</span></label>
                                    <input class="form-control" type="text" placeholder="Enter your phone number" v-model="candidate.phone">
                                </div>
                            </div>
    
                            <div>
                                <div class="form-group">
                                    <label>Password <span style="color: red;">*</span></label>
                                    <input class="form-control" type="text" placeholder="Enter your password" v-model="candidate.password">
                                </div>
                            </div>
    
                            <div>
                                <div class="form-group">
                                    <label>Address</label>
                                    <input class="form-control" type="text" placeholder="Enter your address" v-model="candidate.address">
                                </div>
                            </div>
    
                            <div>
                                <div class="form-group">
                                    <button @click="onSubmit" class="btn btn-success mr-3">Register</button>
                                    <button @click="goBack()" class="btn btn-danger" href="#">Go Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    
    export default {
        data() {
            return {
                candidate: {
                    first_name: '',
                    last_name: '',               
                    address: null,
                    phone: '',
                    email: '',
                    password: '',
                }
            };
        },
    
        methods: {
            onSubmit() {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (!this.candidate.email || !emailPattern.test(this.candidate.email)) {
                    Swal.fire({
                        title: "Error!",
                        text: "Please enter a valid email address",
                        icon: "error"
                    });
                    return;
                }

                if (!this.candidate.password || this.candidate.password.length < 6) {
                    Swal.fire({
                        title: "Error!",
                        text: "Password must be at least 6 characters long",
                        icon: "error"
                    });
                    return;
                }

                this.candidate.first_name = this.candidate.first_name.trim() ? this.candidate.first_name : null;
                this.candidate.last_name = this.candidate.last_name.trim() ? this.candidate.last_name : null;
                this.candidate.email = this.candidate.email.trim() ? this.candidate.email : null;
                this.candidate.password = this.candidate.password.trim() ? this.candidate.password : null;
                this.candidate.phone = this.candidate.phone.trim() ? this.candidate.phone : null;

                if(this.candidate.first_name && this.candidate.last_name && this.candidate.phone && this.candidate.email && this.candidate.password) {
                    this.$store.dispatch("job/addCandidate", this.candidate).then(response => {
                        if (response.code == 200) {
                            Swal.fire({
                                title: "Success!",
                                text: response.response,
                                icon: "success"
                            });
                            this.candidate.first_name = '',
                            this.candidate.last_name = '',
                            this.candidate.address = null,
                            this.candidate.phone = '',
                            this.candidate.email = ''
                            this.candidate.password = ''
                            setTimeout(() => {
                                if (this.$route.query.redirect) {
                                    this.$router.push({
                                        path: '/auth/login',
                                        query: {
                                            redirect: this.$route.query.redirect
                                        }
                                    })
                                } else {
                                    this.$router.push('/auth/login')
                                }
                            }, 2000)
                        } else {
                            Swal.fire({
                                title: "Error!",
                                text: response.response,
                                icon: "error"
                            })
                        }
                    })
                } else {
                    this.candidate.first_name = '',
                    this.candidate.last_name = '',
                    this.candidate.phone = '',
                    this.candidate.email = '',
                    this.candidate.password = '',
                    Swal.fire({
                        title: "Error!",
                        text: 'You must enter all the mandatory fields (*)',
                        icon: "error"
                    })
                }
            },

            goBack() {
                if (this.$route.query.redirect) {
                    this.$router.push({
                        path: '/auth/login',
                        query: {
                            redirect: this.$route.query.redirect
                        }
                    })
                } else {
                    this.$router.push('/auth/login')
                }
            }
        }
    };
</script>

<style scoped>
    .vendor-width {
        width: 399px;
    }
    
    @media screen and (max-width: 550px) {
        .vendor-width {
            width: 300px;
        }
    }
    
    @media screen and (max-width: 420px) {
        .vendor-width {
            width: 280px;
        }
    }
    </style>
    