<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Invoice Create" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Project</label>
                                        <select class="form-control digits" v-model="invoice.project">
                                            <option v-for="(project, index) in projects" :key="index" :value="project.id">{{ project.title }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Quotation</label>
                                        <select class="form-control digits" v-model="invoice.quotation">
                                            <option v-for="(quotation, index) in quotations" :key="index" :value="quotation.id">{{ quotation.quotation_id }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Client <span><button class="btn-success ml-3" v-b-modal.modal-prevent-closing>Add New</button></span></label>
                                        <select class="form-control digits" v-model="invoice.client" @change="getClient">
                                            <option v-for="(client, index) in clients" :key="index" :value="client.user.id">{{ client.company_name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Currency</label>
                                        <select class="form-control digits" v-model="invoice.currency">
                                            <option v-for="(currency, index) in currencies" :key="index">{{ currency }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input class="form-control" type="text" placeholder="Enter email address" v-model="invoice.email">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Client Address</label>
                                        <input class="form-control" type="text" placeholder="Enter quotation address" v-model="invoice.client_address">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Billing Address</label>
                                        <input class="form-control" type="text" placeholder="Enter billing address" v-model="invoice.billing_address">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Invoice Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="invoice.invoice_date"></datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Due Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="invoice.due_date"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Upload Attachment</label>
                                        <b-form-file v-model="invoice.invoice_attachment" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="uploadFile"></b-form-file>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Items <span style="color: red;">*</span></label> 
                                        <ItemTable style="margin-bottom: 10px;" id="item-table" @itemsUpdated="updateItems" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <!-- Total -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Total</label>
                                        <input class="form-control" type="number" placeholder="Enter total amount" disabled v-model="invoice.total" />
                                    </div>

                                    <!-- Vat -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Vat (%)</label>
                                        <div class="d-flex w-100" style="gap:16px">
                                            <input class="form-control" type="number" placeholder="Enter vat (%)" v-model="invoice.vat" />
                                            <input class="form-control" type="number" placeholder="Vat Amount" v-model="vatAmount" disabled/>
                                        </div>
                                    </div>

                                    <!-- Tax -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Tax (%)</label>
                                        <div class="d-flex w-100" style="gap:16px">
                                            <input class="form-control" type="number" placeholder="Enter tax (%)" v-model="invoice.tax" />
                                            <input class="form-control" type="number" placeholder="Tax Amount" v-model="taxAmount" disabled/>
                                        </div>

                                    </div>

                                    <!-- Discount -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Discount (%)</label>
                                        <div class="d-flex w-100" style="gap:16px">
                                            <input class="form-control w-50" type="number" placeholder="Enter discount (%)" v-model="invoice.discount" />
                                            <input class="form-control w-50" type="number" placeholder="Tax Amount" v-model="discountAmount" disabled/>
                                        </div>
                                    </div>

                                    <!-- Grand Total -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Grand Total ({{ invoice.currency ? invoice.currency : "BDT" }})</label>
                                        <input class="form-control" type="number" placeholder="Enter grand total" disabled v-model="invoice.subtotal" />
                                    </div>

                                    <!-- In Words -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">In Words</label>
                                        <input class="form-control" type="text" placeholder="Enter grand total in words" v-model="invoice.in_words" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Remarks</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter remarks..." rows="2" v-model="invoice.remarks"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                        <router-link to="/finance-and-accounts/finance/invoice/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>

    <!-- Client Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add New Client" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <div class="form-group">
                <label>Name (Contact Person)</label>
                <input class="form-control" type="text" v-model="client.first_name">
            </div>

            <div class="form-group">
                <label>Company <span class="btn-success ml-3 p-1 rounded" v-b-modal.modal-prevent-closing-company>Add New </span></label>
                <select class="form-control digits" v-model="client.company" @change="getCompany">
                    <option v-for="(company, index) in companies" :key="index" :value="company.id">{{ company.name }}</option>
                </select>
            </div>

            <div class="form-group">
                <label>Designation</label>
                <input class="form-control" type="text" v-model="client.designation">
            </div>

            <div class="form-group">
                <label>Phone Number</label>
                <input class="form-control" type="text" v-model="client.phone">
            </div>

            <div class="form-group">
                <label>Email</label>
                <input class="form-control" type="text" v-model="client.email">
            </div>

            <div class="form-group">
                <label>Address</label>
                <input class="form-control" type="text" v-model="client.address">
            </div>
        </form>
    </b-modal>
    <!-- Container-fluid Ends-->

    <!-- Container-fluid Ends-->
    <b-modal id="modal-prevent-closing-company" ref="modal" title="Add Company" @show="resetCompanyModal" @hidden="resetClient" @ok="handleClientOk">
        <form ref="form" @submit.stop.prevent="handleClientSubmit">
            <b-form-group label="Company Name" label-for="name-input" invalid-feedback="Company Name is required" :state="nameState">
                <b-form-input id="name-input" v-model="company.name" :state="nameState" required></b-form-input>
            </b-form-group>

            <b-form-group label="Email" label-for="name-input" invalid-feedback="Email address is required" :state="nameState">
                <b-form-input id="name-input" v-model="company.email" :state="nameState" required></b-form-input>
            </b-form-group>

            <b-form-group label="Website" label-for="name-input" >
                <b-form-input id="name-input" v-model="company.website"></b-form-input>
            </b-form-group>

            <b-form-group label="Address" label-for="name-input" invalid-feedback="Company address is required" :state="nameState">
                <b-form-input id="name-input" v-model="company.address" :state="nameState" required></b-form-input>
            </b-form-group>

            <b-form-group label="BIN" label-for="name-input" >
                <b-form-input id="name-input" v-model="company.bin"></b-form-input>
            </b-form-group>

            <b-form-group label="TIN" label-for="name-input" >
                <b-form-input id="name-input" v-model="company.tin"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { computed } from "vue";
import ItemTable from "./item-table.vue";
import moment from "moment";

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        ItemTable
    },

    data() {
        return {
            discountAmount: computed(() => {
                if (this.invoice.total !== 0) {
                    const discount = this.invoice.discount || 0;
                    let total = this.invoice.total + discount * (this.invoice.total / 100)
                    let gtotal = total - this.invoice.total

                    return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                }
                return 0;
            }),
            taxAmount: computed(() => {
                if (this.invoice.total !== 0) {
                    const tax = this.invoice.tax || 0;
                    let total = this.invoice.total + tax * (this.invoice.total / 100)
                    let gtotal = total - this.invoice.total

                    return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                }
                return 0;
            }),

            vatAmount: computed(() => {
                if (this.invoice.total !== 0) {
                    const vat = this.invoice.vat || 0;
                    let total = this.invoice.total + vat * (this.invoice.total / 100)
                    let gtotal = total - this.invoice.total

                    return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                }
                return 0;
            }),
            invoice: {
                quotation: null,
                project: null,
                email: '',
                currency: 'BDT',
                client_address: '',
                billing_address: '',
                invoice_date: null,
                invoice_attachment: null,
                due_date: null,
                client: '',
                details: [],
                details_in_depth: [],

                total: computed(() => {
                    let total = 0;
                    if (this.invoice.details_in_depth.length >= 1) {
                        this.invoice.details_in_depth.map((item) => {
                            total = total + item.amount;
                        });
                    }
                    return total;
                }),
                tax: null,
                vat: null,
                discount: null,
                subtotal: computed(() => {
                    if (this.invoice.total !== 0) {
                        const tax = this.invoice.tax || 0;
                        const vat = this.invoice.vat || 0;
                        const discount = this.invoice.discount || 0;
                        let gtotal =
                            this.invoice.total +
                            tax * (this.invoice.total / 100) +
                            vat * (this.invoice.total / 100) -
                            discount * (this.invoice.total / 100);
                        return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                    }
                    return 0;
                }),
                in_words: '',
                remarks: ''
            },
            nameState: null,

            currencies: ['BDT', 'USD', 'EUR'],

            client: {
                first_name: '',
                company: null,
                designation: '',
                phone: '',
                email: '',
                address: '',
                status: true
            },

            company: {
                name: '',
                email: '',
                website: '',
                address: '',
                bin: '',
                tin: ''
            }
        };
    },

    computed: {
        ...mapState({
            items: state => state.item.items,
            clients: state => state.client.client_without_pg,
            projects: state => state.project.project,
            quotations: state => state.quotation.quotation,
            companies: state => state.client.company
        })
    },

    mounted() {
        if (this.$route.params.id == 'new') {
            this.invoice.quotation = ''
        } else {
            this.invoice.project = this.$route.params.id
        }

        this.$store.dispatch("item/fetchItem")
        this.$store.dispatch("project/fetchProject")
        this.$store.dispatch("quotation/fetchQuotation")
        this.$store.dispatch("client/fetchClient_without_pg")
        this.$store.dispatch("client/fetchCompany");
    },

    methods: {
        uploadFile(e) {
            this.invoice.invoice_attachment = e.target.files[0];
        },

        getCompany() {
            this.$store.dispatch("client/fetchCompanyDetails", this.client.company).then(result => {
                this.client.address = result.data.address
            })
        },

        getClient() {
            this.$store.dispatch("client/fetchClientByUserId", this.invoice.client).then(result => {
                this.invoice.email = result.data.user.email
                this.invoice.client_address = result.data.company.address
                this.invoice.billing_address = result.data.company.address
            })
        },

        resetCompanyModal() {
            this.company.name = ''
            this.company.email = ''
            this.company.website = ''
            this.company.address = ''
            this.company.bin = ''
            this.company.tin = ''
            this.nameState = null
        },

        resetModal() {
            this.client.first_name = ''
            this.client.company = '',
                this.client.designation = '',
                this.client.phone = '',
                this.client.email = '',
                this.client.address = ''
        },

        updateItems(data) {
            if (data) {
                this.invoice.details_in_depth = data;
                this.invoice.details = data;
            }
        },

        onSubmit() {
            if (this.invoice.invoice_date !== null) {
                this.invoice.invoice_date = moment(String(this.invoice.invoice_date)).format("YYYY-MM-DD");
            }
            if (this.invoice.due_date !== null) {
                this.invoice.due_date = moment(String(this.invoice.due_date)).format("YYYY-MM-DD");
            }

            this.invoice.details = this.invoice.details?.length > 0 ? this.invoice.details : null

            if(this.invoice.details) {
                this.$store.dispatch("invoice/addInvoice", this.invoice).then(response => {
                    // console.log(response)
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        setTimeout(() => {
                            this.$router.push('/finance-and-accounts/finance/invoice/list')
                        }, 2000)

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            } else {
                Swal.fire({
                    title: "Error!",
                    text: 'You must enter minimum 1 item',
                    icon: "error"
                });
            }
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.$store.dispatch("client/addClient", this.client).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.invoice.client = response.data.user
                    this.invoice.email = response.data.email
                    this.invoice.client_address = response.data.address
                    this.invoice.billing_address = response.data.address

                    this.client.first_name = '',
                        this.client.company = null,
                        this.client.designation = '',
                        this.client.phone = '',
                        this.client.email = '',
                        this.client.address = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },

        handleClientOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleClientSubmit()
        },

        handleClientSubmit() {
            if (!this.checkFormValidity()) {
                return
            }
            this.$store.dispatch("client/addCompany", this.company).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.client.company = response.data.id
                    this.client.address = response.data.address

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-company')
            })
        }
    }
};
</script>
