<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Ledger" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Ledger</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-wrap" style="gap: 20px;">
                                    <b-input-group class="datatable-btn" style="width: 340px;">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                        <feather type="filter"></feather>
                                        <h7>Filter</h7>
                                    </div>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="note || income_statement || group">
                                        <feather type="x"></feather>
                                        <h7>Clear All</h7>
                                    </div>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <b-form-select v-model="group" :options="formattedGroup"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="income_statement" :options="formattedStatement"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="note" :options="formattedNote"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!accountLedgerLoading && !accountLedgerError">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="accountLedgersPG" :fields="tablefields" :per-page="perPage">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(group)="data">
                                        {{ data.item.group != null ? data.item.group.title : "N/A" }}
                                    </template>

                                    <template #cell(note)="data">
                                        <small style="font-weight: bold"> {{ data.item.note != null ? data.item.note.title : "N/A" }}</small>
                                    </template>

                                    <template #cell(income_statement)="data">
                                        <small style="font-weight: bold"> {{ data.item.income_statement!=null?data.item.income_statement.title:"N/A" }}</small>
                                    </template>

                                    <template #cell(lid)="data">
                                        <span>
                                            <router-link :to="{ path: '/finance-and-accounts/account/ledger/' + data.item.lid }">
                                                {{ data.item.lid }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(data.item.id)" title="Delete"></feather>
                                            <feather @click="editLedger(data.item)" type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Create Ledger -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Create Ledger" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Pre-defined Category" label-for="name-input" invalid-feedback="Group is required">
                <b-form-select v-model="category_selected" @change="updateGroup">
                    <b-form-select-option v-for="option in accountCategories" :value="option.id" @click="updateGroup('hello')">{{ option.title }}</b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-form-group label="Group" label-for="name-input" invalid-feedback="Group is required">
                <b-form-select v-model="ledger.group">
                    <b-form-select-option v-for="option in options" :value="option.id">{{ option.title }}</b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-form-group label="Note" label-for="name-input" invalid-feedback="Note is required">
                <b-form-select v-model="ledger.note">
                    <b-form-select-option v-for="option in accountNotes" :value="option.id">{{ option.title }}</b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-form-group label="Income Statement" label-for="name-input" invalid-feedback="Note is required">
                <b-form-select v-model="ledger.income_statement">
                    <b-form-select-option v-for="option in incomeStructure" :value="option.id">{{ option.title }}</b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-form-group label="Title" label-for="name-input" invalid-feedback="Title is required">
                <b-form-input id="name-input" v-model="ledger.title" placeholder="Enter ledger title"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Edit Ledger -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Ledger" @show="resetModal" @hidden="resetModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Pre-defined Category" label-for="name-input" invalid-feedback="Group is required">
                <b-form-select v-model="category_selected" @change="updateGroup">
                    <b-form-select-option v-for="option in accountCategories" :value="option.id">{{ option.title }}</b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-form-group label="Group" label-for="name-input" invalid-feedback="Group is required">
                <!--<b-form-select v-model="edit_ledger.group" :options="options"></b-form-select>-->
                <b-form-select v-model="edit_ledger.group">
                    <b-form-select-option v-for="option in options" :value="option.id">{{ option.title }}</b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-form-group label="Note" label-for="name-input" invalid-feedback="Note is required">
                <!--<b-form-select v-model="edit_ledger.group" :options="options"></b-form-select>-->
                <b-form-select v-model="edit_ledger.note">
                    <b-form-select-option v-for="option in accountNotes" :value="option.id">{{ option.title }}</b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-form-group label="Income Statement" label-for="name-input" invalid-feedback="Note is required">
                <b-form-select v-model="edit_ledger.income_statement">
                    <b-form-select-option v-for="option in incomeStructure" :value="option.id">{{ option.title}}</b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-form-group label="Title" label-for="name-input" invalid-feedback="Title is required">
                <b-form-input id="name-input" v-model="edit_ledger.title" placeholder="Enter ledger title"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this item?</div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import notes from "@/pages/smicee/accounts/notes.vue";
import IncomeStatement from "@/pages/smicee/accounts/income-statement.vue";
import incomeStatement from "@/pages/smicee/accounts/income-statement.vue";

export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'lid',
                    label: 'Ledger ID',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'group',
                    label: 'Group',
                    sortable: true
                },
                {
                    key: 'group.pre_defined.title',
                    label: 'Pre-Defined Categories',
                    sortable: true
                },
                {
                    key: 'income_statement',
                    label: 'Income Statement',
                    sortable: true
                },
                {
                    key: 'note',
                    label: 'Note',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            items: [],

            ledger: {
                title: '',
                group: '',
                note: '',
                income_statement: ''
            },
            category_selected: "",

            edit_ledger: {
                title: '',
                group: '',
                note: '',
                income_statement: ""
            },

            options: [],

            filterShow: false,
            filter: null,
            note: '',
            income_statement: '',
            group: '',

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            initialLoad: true,
        };
    },

    computed: {
        incomeStatement() {
            return incomeStatement
        },

        IncomeStatement() {
            return IncomeStatement
        },

        notes() {
            return notes
        },

        ...mapState('account', ["incomeStructure", "accountNotes", "accountLedgersPG", "accountLedgers_count", 'accountCategories', "accountGroups", "accountGroupLoading", "accountGroupError", "accountLedgerError", "accountLedgerLoading", "submitError", "submitSuccess", "submitLoading"]),

        formattedGroup() {
            return [{
                    value: '',
                    text: 'Select group',
                    disabled: true
                },
                ...this.options.map(grp => ({
                    value: grp.id,
                    text: grp.title
                }))
            ];
        },

        formattedNote() {
            return [{
                    value: '',
                    text: 'Select note',
                    disabled: true
                },
                ...this.accountNotes.map(note => ({
                    value: note.id,
                    text: note.title
                }))
            ];
        },

        formattedStatement() {
            return [{
                    value: '',
                    text: 'Select income statement',
                    disabled: true
                },
                ...this.incomeStructure.map(inc => ({
                    value: inc.id,
                    text: inc.title
                }))
            ];
        }
    },

    created() {
        // this.$store.dispatch('account/fetchAccountLedger')
        this.$store.dispatch('account/fetchAccountNote')
        this.$store.dispatch('account/fetchIncomeStructureList')
        this.$store.dispatch('account/fetchAccountGroup')
        this.$store.dispatch('account/fetchAccountCategory')

        // this.items = this.accountLedgersPG
        // this.totalRows = this.items.length
        this.options = this.accountGroups
    },

    watch: {
        accountGroups(oldValue, newValue) {
            this.options = this.accountGroups
        },
        accountCategories(oldValue, newValue) {},
        accountNotes(oldValue, newValue) {},
        incomeStructure(oldValue, newValue) {},
       

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/account/ledger',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("account/fetchAccountLedgerWithPG", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    search_term: this.filter,
                    group: this.group,
                    income_statement: this.income_statement,
                    note: this.note
                }).then(() => {
                    this.totalRows = this.accountLedgers_count;
                    this.initialLoad = false;
                });
            }

        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/account/ledger',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("account/fetchAccountLedgerWithPG", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    search_term: this.filter,
                    group: this.group,
                    income_statement: this.income_statement,
                    note: this.note
                }).then(() => {
                    this.totalRows = this.accountLedgers_count;
                    this.initialLoad = false;
                });
            }

        },

        filter(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("account/fetchAccountLedgerWithPG", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.filter,
                    group: this.group,
                    income_statement: this.income_statement,
                    note: this.note
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.accountLedgers_count;
                    this.initialLoad = false;
                });
            }

        },

        group(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("account/fetchAccountLedgerWithPG", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.filter,
                    group: this.group,
                    income_statement: this.income_statement,
                    note: this.note
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.accountLedgers_count;
                    this.initialLoad = false;
                });
            }

        },

        income_statement(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("account/fetchAccountLedgerWithPG", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.filter,
                    group: this.group,
                    income_statement: this.income_statement,
                    note: this.note
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.accountLedgers_count;
                    this.initialLoad = false;
                });
            }

        },

        note(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("account/fetchAccountLedgerWithPG", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.filter,
                    group: this.group,
                    income_statement: this.income_statement,
                    note: this.note
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.accountLedgers_count;
                    this.initialLoad = false;
                });
            }

        }
    },

    mounted() {
        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {

                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitial();
            } else {

                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitial();
            }
        }
    },

    methods: {
        fetchInitial() {
            this.$store.dispatch("account/fetchAccountLedgerWithPG", {
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    search_term: this.filter,
                    group: this.group,
                    income_statement: this.income_statement,
                    note: this.note
            }).then(() => {
                this.totalRows = this.accountLedgers_count;
                this.initialLoad = false;
            });
        },
        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        clearFilter() {
            this.filterShow = false,
                this.filter = null,
                this.note = '',
                this.income_statement = '',
                this.group = ''
        },

        updateGroup() {
            this.$store.dispatch('account/fetchAccountGroupByCategory', this.category_selected)
        },

        resetModal() {
            this.ledger.title = ''
            this.ledger.group = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleSubmit() {
            this.$store.dispatch("account/addAccountLedger", {
                ledger: this.ledger,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter,
                group: this.group,
                income_statement: this.income_statement,
                note: this.note
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.ledger.title = ''
                    this.ledger.group = ''
                    this.ledger.note = ''
                    this.ledger.income_statement = ''
                    this.category_selected = ""

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editLedger(item) {
           
            this.edit_ledger.id = item.id
            this.edit_ledger.title = item.title
            this.edit_ledger.group = item.group.id
            this.edit_ledger.note = item.note.id
            this.edit_ledger.income_statement = item.income_statement.id
            this.category_selected = item.group.pre_defined.id
        },

        // resetEditModal() {
        //     this.edit_item.name = ""
        //     this.edit_item.id = null
        //     this.edit_item.rate = null
        //     this.edit_item.description = ""
        // },

        handleEditSubmit() {
            this.$store.dispatch("account/updateAccountLedger", {
                ledger: this.edit_ledger,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter,
                group: this.group,
                income_statement: this.income_statement,
                note: this.note
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_ledger.id = ""
                    this.edit_ledger.title = ''
                    this.edit_ledger.group = ''
                    this.edit_ledger.note = ''
                    this.edit_ledger.income_statement = ''
                    this.category_selected = ""

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        deleteItem(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("account/deleteAccountLedger", {
                id: this.delete_value,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter,
                group: this.group,
                income_statement: this.income_statement,
                note: this.note
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
