<template>
<div>
    <Breadcrumbs main="EOD" title="EOD Reports" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">

                        <b-row class="d-flex justify-content-center align-items-center">
                            <b-col xl="6" class="d-flex flex-wrap" style="gap: 20px">
                                <b-input-group style="width: 340px;">
                                    <b-form-input v-model="filter" placeholder="Type to Search" style="box-shadow: none;"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                    <feather type="filter"></feather>
                                    <h7>Filter</h7>
                                </div>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="filterShow || start_date || end_date || statusSelected ||selectedEmployee ">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="3" label="Per page" class="mb-0 datatable-select w-100">
                                    <b-form-select v-model="perPage" :options="pageOptions" style="width: 100%;"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedDepartment" :options="formattedDepartment"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment">
                                        <b-form-select v-model="selectedDesignation" :options="formattedDesignation"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDesignation">
                                        <b-form-select v-model="selectedEmployee" :options="formattedEmployee"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(eod_id)="data">
                                        <router-link :to="`/eod/details/${data.item.id}`" class="link-style"> <span v-b-tooltip.hover title="Report Details"> {{ data.item.eod_id}} </span></router-link>
                                    </template>

                                    <template #cell(reported_by)="data">
                                        {{ data.item.reported_by.first_name }} {{ data.item.reported_by.last_name }}
                                    </template>

                                    <!-- <template #cell(report)="data">
                                        <p v-if="data.item.report"> {{ truncateText(data.item.report, 50) }}</p>
                                    </template> -->

                                    <template #cell(status)="row">
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'PENDING'">
                                            <b-badge pill variant="warning" v-b-modal.modal-prevent-closing-status v-b-tooltip.hover title="Update Status">{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'APPROVED'">
                                            <b-badge pill variant="success" v-b-modal.modal-prevent-closing-status v-b-tooltip.hover title="Update Status">{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'DISCUSSION'">
                                            <b-badge pill variant="primary" v-b-modal.modal-prevent-closing-status v-b-tooltip.hover title="Update Status">{{ row.value }}</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(refer_to)="data">
                                        <span>
                                            <div v-if="data.item.reported_to !== null && data.item.reported_to.length > 0">
                                                <div class="customers">
                                                    <ul>
                                                        <ul v-for="(member, index) in data.item.reported_to.slice(0,5)" :key="index">
                                                            <li v-if="member.photo" class="d-inline-block" v-b-tooltip.hover :title="member.full_name"><img class="img-30 rounded-circle" :src="host + member.photo" alt="" data-original-title=""></li>
                                                            <li v-else class="d-inline-block" v-b-tooltip.hover :title="member.full_name"><img class="img-30 rounded-circle" src="../../../assets/images/user/3.jpg" alt="" data-original-title=""></li>
                                                        </ul>
                                                        <li class="d-inline-block mr-2" v-if="data.item.reported_to.length > 5">
                                                            <p class="f-12">+ {{ data.item.reported_to.length - 5 }}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <p>N/A</p>
                                            </div>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex;  gap: 12px;">
                                            <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(data.item)" title="Reply"></feather>
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteEOD(data.item.id)" title="Delete"></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-row class="d-flex align-items-center">
                            <b-col xl="6">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="mb-0"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Container-fluid Ends-->
    <b-modal id="modal-scrollable" ref="modal" title="EOD Reply" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <h6>Already Refered</h6>
        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="user in this.my_reply.refer_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ user.first_name}}</h7>
                    <b-icon icon="x" style="color: white;"></b-icon>
                </div>
            </div>
        </div>
        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
        <div style="margin-top: 30px;" v-if="eod_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in eod_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleReplySubmit()">
                <button @click="handleReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>

    <!--  Allocate Delete Modal -->
    <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this referee?</div>
    </b-modal>

    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this EOD?</div>
    </b-modal>

    <!-- Update Status -->
    <b-modal id="modal-prevent-closing-status" ref="modal" title="Update EOD Status" @show="resetModal" @hidden="resetModal" @ok="handleStatusOk">
        <form ref="form" @submit.stop.prevent="handleStatusSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="formattedOptions"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Multiselect from 'vue-multiselect';
import moment from "moment";
import {
    mapState
} from 'vuex';
import {
    myHost
} from '../../../_helpers/host';

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'eod_id',
                    label: 'EOD ID',
                    sortable: true
                },
                {
                    key: 'reported_by',
                    label: 'Reported By',
                    sortable: true
                },
                {
                    key: 'created_date',
                    label: 'EOD Date',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'refer_to',
                    label: 'Reported to',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            update_eod_id: '',
            statuses: [{
                    id: 1,
                    name: 'Pending',
                    value: 'PENDING'
                },
                {
                    id: 2,
                    name: 'Approved',
                    value: 'APPROVED'
                },
                {
                    id: 3,
                    name: 'Discussion Required',
                    value: 'DISCUSSION',
                }
            ],
            selectedStatus: null,
            start_date: null,
            end_date: null,
            statusSelected: '',
            selectedDepartment: '',
            selectedDesignation: '',
            selectedEmployee: '',
            assignedMembers: [],
            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            temp_id: '',
            delete_value: null,
            initialLoad: true,
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            filterShow: false
        };
    },

    watch: {
        selectedDepartment(newVal, oldVal) {
            this.$store.dispatch("department/fetchDesignationByDepartment", newVal)
        },

        selectedDesignation(newVal, oldVal) {
            this.$store.dispatch("department/fetchEmployeeByDesignation", newVal)
        },

        statusSelected(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("eod/fetchAllEOD", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    reported_by: this.selectedEmployee
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        selectedEmployee(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("eod/fetchAllEOD", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    reported_by: this.selectedEmployee
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        start_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.start_date !== null) {
                        this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("eod/fetchAllEOD", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            reported_by: this.selectedEmployee
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("eod/fetchAllEOD", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            reported_by: this.selectedEmployee
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }
            }

        },

        end_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.end_date !== null) {
                        this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("eod/fetchAllEOD", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            reported_by: this.selectedEmployee
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("eod/fetchAllEOD", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            reported_by: this.selectedEmployee
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        },

        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/eod/list',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("eod/fetchAllEOD", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    reported_by: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/eod/list',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("eod/fetchAllEOD", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    reported_by: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }
        }
    },

    computed: {
        ...mapState({
            items: state => state.eod.all_eod,
            count: state => state.eod.all_eod_count,
            user_list: state => state.employee.employee_list,
            eod_reply: state => state.eod.eod_reply,
            loading: state => state.eod.eodLoading,
            error: state => state.eod.eodError,
            department_list: state => state.department.departments,
            designation_list: state => state.department.designation_by_department,
            employee_list: state => state.department.employee_by_designation
        }),

        formattedEmployee() {
            return [{
                    value: '',
                    text: 'Select Employee',
                    disabled: true
                },
                ...this.employee_list.map(emp => ({
                    value: emp.id,
                    text: emp.full_name
                }))
            ];
        },

        formattedDesignation() {
            return [{
                    value: '',
                    text: 'Select Designation',
                    disabled: true
                },
                ...this.designation_list.map(desg => ({
                    value: desg.id,
                    text: desg.title
                }))
            ];
        },

        formattedDepartment() {
            return [{
                    value: '',
                    text: 'Select Department',
                    disabled: true
                },
                ...this.department_list.map(dept => ({
                    value: dept.id,
                    text: dept.name
                }))
            ];
        },

        formattedOptions() {
            return [{
                    value: '',
                    text: 'Select Status',
                    disabled: true
                },
                ...this.statuses.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        }
    },

    mounted() {
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("department/fetchDepartments");
        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {

                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitial();
            } else {

                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitial();
            }
        }

    },

    methods: {
        fetchInitial() {
            this.$store.dispatch("eod/fetchAllEOD", {
                perPage: this.perPage,
                currentPage: this.currentPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                reported_by: this.selectedEmployee
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false;
            });
        },
        clearFilter() {
            this.filterShow = false,
                this.start_date = null,
                this.end_date = null,
                this.statusSelected = '',
                this.selectedEmployee = '';
            this.selectedDepartment = '',
                this.selectedDesignation = ''
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },
        getStatus(status, id) {
            this.update_eod_id = id
            this.selectedStatus = status
        },
        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.my_reply.refer_to = type.reported_to
            this.$store.dispatch("eod/fetchEODReply", this.my_reply.id)
        },
        deletetAllocation(id) {
            this.temp_id = id
        },
        handleAllocationDeleteOk() {
            this.$store.dispatch("eod/EODDeleteRefer", {
                id: this.my_reply.id,
                refer_id: this.temp_id,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                reported_by: this.selectedEmployee,
                alleod: true,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },
        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("eod/EODAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                reported_by: this.selectedEmployee,
                alleod: true,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })

        },

        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteEOD(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("eod/deleteEOD", {
                delete: this.delete_value,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                reported_by: this.selectedEmployee,
                from_all: true
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleReplySubmit()

        },

        handleReplySubmit() {
            if (this.my_reply.reply) {
                this.$store.dispatch("eod/addEODReply", {
                    eod: this.my_reply.id,
                    reply: this.my_reply.reply
                }).then(response => {

                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.my_reply.id = '',
                            this.my_reply.reply = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

                this.$nextTick(() => {
                    this.$bvModal.hide('modal-scrollable')
                })
            }
        },
        handleStatusOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleStatusSubmit()
        },

        handleStatusSubmit() {
            this.$store.dispatch("eod/updateEODStatus", {
                id: this.update_eod_id,
                status_for_change: this.selectedStatus,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                reported_by: this.selectedEmployee,
                from_all: true
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-status')
            })
        },
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
    word-break: break-all;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.img-30 {
    width: 30px !important;
    height: 30px !important;
}
</style>
