
let final_list={
    "data":[
        {
            "path": "/dashboard",
            "title": "Dashboard",
            "icon": "home",
            "type": "link",
            "active": false
        },
    ]
}
let employee_data=[
    {
        "headTitle1": "My Workspace",
        "headTitle2": "Manage Your Daily Dealings",
        "type": "headtitle"
    },
    {
        "path": "/my-workspace/my-updates",
        "title": "My Updates",
        "icon": "bell",
        "type": "link",
    },
    {
        "title": "EOD Report",
        "type": "sub",
        "active": false,
        "icon": "edit-3",
        "children": [
            {
                "path": "/my-workspace/eod/my-eod-report",
                "title": "My EOD",
                "type": "link"
            },
            {
                "path": "/my-workspace/eod/eod-report",
                "title": "Submit EOD",
                "type": "link"
            }
        ]
    },
    {
        "title": "My Projects",
        "type": "sub",
        "active": false,
        "icon": "database",
        "children": [
            {
                "path": "/project/my-project",
                "title": "Projects",
                "type": "link"
            },
            {
                "path": "/project/my-task",
                "title": "Tasks",
                "type": "link"
            }
        ]
    },
    {
        "title": "My Tickets",
        "type": "sub",
        "active": false,
        "icon": "disc",
        "children": [
            {
                "path": "/my-workspace/ticket/my-tickets",
                "title": "Tickets Raised To Me",
                "type": "link"
            },
            {
                "path": "/my-workspace/ticket/raise-a-ticket",
                "title": "Raise a Ticket",
                "type": "link"
            }
        ]
    },
    {
        "title": "My leaves",
        "icon": "book-open",
        "type": "sub",
        "active": false,
        "children": [
            {
                "path": "/my-workspace/apply-for-leave",
                "title": "Leaves",
                "type": "link"
            },
            {
                "path": "/my-workspace/apply-for-leave/create",
                "title": "Apply For Leave",
                "type": "link"
            }
        ]
    },
    {
        "title": "My Requisitions",
        "icon": "book",
        "type": "sub",
        "active": false,
        "children": [
            {
                "path": "/my-workspace/my-requisition",
                "title": "Requisitions",
                "type": "link"
            },
            {
                "path": "/my-workspace/requisition-create",
                "title": "Apply For Requisition",
                "type": "link"
            }
        ]
    },
    {
        "path": "/my-workspace/trainings",
        "title": "My Trainings",
        "icon": "settings",
        "type": "link",
    },
    {
        "path": "/my-workspace/grievance",
        "title": "My Grievances",
        "icon": "help-circle",
        "type": "link",
    },
    {
        "path": "/my-workspace/my-assets",
        "title": "My Assets",
        "icon": "archive",
        "type": "link",
    },
    {
        "path": "/my-workspace/my-contact",
        "title": "My Contact",
        "icon": "phone-call",
        "type": "link",
    }
]


let vendor_data=[
    {
        "headTitle1": "Vendor",
        "headTitle2": "Request for Quotations",
        "type": "headtitle"
    },
    {
        "title": "RFQ",
        "type": "sub",
        "active": false,
        "icon": "edit-3",
        "children": [
            {
                "path": "/vendor/rfq/1/10",
                "title": "RFQ",
                "type": "link"
            },
            {
                "path": "/vendor/rfq/my-rfq/1/10",
                "title": "Submissions",
                "type": "link"
            }
        ]
    },
    {
        "path": "/vendor/projects",
        "title": "Projects",
        "icon": "database",
        "type": "link"
    },
    {
        "path": "/vendor/purchase_order",
        "title": "Purchase Order",
        "icon": "database",
        "type": "link"
    },
    {
        "path": "/vendor/bill",
        "title": "Bills",
        "icon": "database",
        "type": "link"
    },
]

let candidate_data=[
    {
        "headTitle1": "Candidates",
        "headTitle2": "Apply for job",
        "type": "headtitle"
    },

    {
        "path": "/candidate/careers",
        "title": "Careers",
        "icon": "briefcase",
        "type": "link"
    },
    {
        "path": "/candidate/application",
        "title": "Applications",
        "icon": "mail",
        "type": "link"
    },
]


let user_data=JSON.parse(localStorage.getItem('user'))
if (localStorage.getItem('user')!=null){

    if(user_data.is_candidate){

        final_list.data=[...final_list.data, ...candidate_data]


    }else if(user_data.is_employee){
        final_list.data=[...final_list.data, ...employee_data]

    }else if(user_data.is_vendor){

        final_list.data=[...final_list.data, ...vendor_data]

    }
}



let profile_head_title=[
    {
        "headTitle1": "Profile",
        "headTitle2": "Privacy, Security & Account",
        "type": "headtitle"
    },
    {
        "path": "/user/profile",
        "title": "My Profile",
        "icon": "user",
        "type": "link",
        "active": false
    },
    {
        "path": "/user/change-password",
        "title": "Change Password",
        "icon": "lock",
        "type": "link",
        "active": false
    },
    {
        "path": "/auth/login",
        "title": "Logout",
        "icon": "log-in",
        "type": "link",
        "active": false
    }
]


let permissions=JSON.parse(localStorage.getItem('permissions'))

if (localStorage.getItem("permissions") !== null){
    let operations_head_title= {
        "headTitle1": "Operations",
        "headTitle2": "Project, CRM & Procurement",
        "type": "headtitle"
    }

    let Operations=[
        {
            "title": "Project",
            "icon": "database",
            "type": "sub",
            "active": false,
            "children": [
                {
                    "path": "/project/list",
                    "title": "Projects",
                    "type": "link",
                    "code": "view_projects"
                }

            ]
        },
        {
            "title": "CRM",
            "icon": "user-check",
            "type": "sub",
            "active": false,
            "children": [
                {
                    "path": "/crm/leads/list",
                    "title": "Leads",
                    "type": "link",
                    "code": "view_leads"
                },
                {
                    "path": "/crm/client/list",
                    "title": "Clients",
                    "type": "link",
                    "code": "view_clients"
                }
            ]
        },
        {
            "title": "Procurement",
            "icon": "file-text",
            "type": "sub",
            "active": false,
            "children": [ 
                {
                    "path": "/procurement/vendor/contact/list",
                    "title": "Vendor Contacts",
                    "type": "link",
                    "code": "view_vendor_contacts"
                },
                {
                    "path": "/procurement/vendor/list",
                    "title": "Vendor List",
                    "type": "link",
                    "code": "view_vendor_list"
                },
                {
                    "path": "/procurement/rfp/list",
                    "title": "RFP",
                    "type": "link",
                    "code": "view_rfp"
                }
            ]
        },
        {
            "path": "/eod/list",
            "title": "EOD Reports",
            "icon": "edit-3",
            "type": "link",
            "active": false,
            "code": "view_eod"
        }]
    let not_yet=true
    Operations.forEach((item) => {


        if (item.type=="sub"){

            let children=[]
            item.children.forEach((sub_item)=>{
                if (permissions.includes(sub_item.code)){
                    children.push(sub_item)
                }
            })
            item.children=children
            if (item.children.length!=0){
                if (not_yet) {
                    final_list.data.push(operations_head_title)
                    not_yet=false
                }
                final_list.data.push(item)
            }


        }else{
            if (permissions.includes(item.code)){
                if (not_yet) {
                    final_list.data.push(operations_head_title)
                    not_yet=false
                }
                final_list.data.push(item)

            }
        }
    })



// console.log(localStorage.getItem('permissions'))
    let admin_head=
        {
            "headTitle1": "HR & Admin",
            "headTitle2": "Manage Your Human Resources and Administration",
            "type": "headtitle"
        }

    let admin=[
        {
            "path": "/hr/policy",
            "title": "Policy",
            "icon": "info",
            "type": "link",
            "active": false,
            "code": "view_Policy"
        },

        {
            "path": "/hr/compliance",
            "title": "Compliance",
            "icon": "message-square",
            "type": "link",
            "active": false,
            "code": "view_compliance"
        },
        {
            "path": "/finance-and-accounts/account/requisition/list",
            "title": "Requisition",
            "icon": "book",
            "type": "link",
            "code": "view_payroll"
        },
        {
            "path": "/hr/holiday-list",
            "title": "Holidays",
            "icon": "calendar",
            "type": "link",
            "active": false,
            "code": "view_holidays"
        },
        {
            "path": "/hr/grievance/list",
            "title": "Grievance",
            "icon": "help-circle",
            "type": "link",
            "active": false,
            "code": "view_grievance"
        },
        {
            "path": "/hr/ticket/list",
            "title": "Ticket",
            "icon": "disc",
            "type": "link",
            "active": false,
            "code": "view_ticket"
        },

        {
            "path": "/hr/on-boarding",
            "title": "On Boarding",
            "icon": "info",
            "type": "link",
            "active": false,
            "code": "view_Policy"
        },
        {
            "title": "Payroll",
            "type": "sub",
            "icon": "file-minus",
            "active": false,
            "children": [
                {
                    "path": "/hr/payroll/payroll-items/list",
                    "title": "Payroll Items",
                    "type": "link",
                    "code": "view_payroll_items"
                },
                {
                    "path": "/hr/payroll/designation-payroll/list",
                    "title": "Designation - Payroll",
                    "type": "link",
                    "code": "view_designation_payroll"
                },
                {
                    "path": "/hr/payroll/payroll-list",
                    "title": "Payroll",
                    "type": "link",
                    "code": "view_payroll"
                }

            ]
        },

        {
            "title": "Organization",
            "type": "sub",
            "icon": "users",
            "active": false,
            "children": [
                {
                    "path": "/hr/organization/department",
                    "title": "Department",
                    "type": "link",
                    "code": "view_department"
                },
                {
                    "path": "/hr/organization/designation",
                    "title": "Designation",
                    "type": "link",
                    "code": "view_designation"
                },
                {
                    "path": "/hr/organization/employee",
                    "title": "Employee",
                    "type": "link",
                    "code": "view_employee"
                },
                {
                    "path": "/hr/organization/setting/create",
                    "title": "Organization Settings",
                    "type": "link",
                    "code": "view_employee"
                },
                {
                    "path": "/hr/organization/change-password-requests",
                    "title": "Change Password Requests",
                    "type": "link",
                    "code": "view_change_pass"
                }
            ]
        },

        {
            "path": "/hr/time-sheet",
            "title": "Attendance",
            "icon": "user-check",
            "type": "link",
            "active": false,
            "code": "view_Policy"
        },

        {
            "title": "Asset Management",
            "type": "sub",
            "icon": "archive",
            "active": false,
            "children": [
                {
                    "path": "/hr/asset-management/asset/list",
                    "title": "Assets",
                    "type": "link",
                    "code": "view_assets"
                },
                {
                    "path": "/hr/asset-management/asset-allocations/list",
                    "title": "Asset Allocations",
                    "type": "link",
                    "code": "view_asset_allocations"
                },
                {
                    "path": "/hr/asset-management/asset-type",
                    "title": "Asset Type",
                    "type": "link",
                    "code": "view_assets"
                }
            ]
        },
        {
            "title": "Leave Management",
            "type": "sub",
            "icon": "book-open",
            "active": false,
            "children": [
                {
                    "path": "/hr/leave-management/leave-requests",
                    "title": "Leave Requests",
                    "type": "link",
                    "code": "view_leave_requests"
                },
                {
                    "path": "/hr/leave-management/leave-type",
                    "title": "Leave Type",
                    "type": "link",
                    "code": "view_leave_type"
                }
            ]
        },
        {
            "title": "Training Management",
            "type": "sub",
            "icon": "settings",
            "active": false,
            "children": [
                {
                    "path": "/hr/training-management/training/list",
                    "title": "Trainings",
                    "type": "link",
                    "code": "view_trainings"
                },
                {
                    "path": "/hr/training-management/training-type",
                    "title": "Training Type",
                    "type": "link",
                    "code": "view_training_type"
                },
                {
                    "path": "/hr/training-management/trainer/list",
                    "title": "Trainers",
                    "type": "link",
                    "code": "view_trainers"
                }
            ]
        },
        {
            "title": "Job",
            "icon": "briefcase",
            "type": "sub",
            "active": false,
            "children": [
                {
                    "path": "/job/dashboard",
                    "title": "Dashboard",
                    "type": "link",
                    "code": "view_jobs"
                },
                {
                    "path": "/job/job/list",
                    "title": "Jobs",
                    "type": "link",
                    "code": "view_jobs"
                },
                {
                    "path": "/job/canditate/list",
                    "title": "Candidates",
                    "type": "link",
                    "code": "view_jobs"
                },
                {
                    "path": "/job/messages",
                    "title": "Messages",
                    "type": "link",
                    "code": "view_jobs"
                },
                {
                    "path": "/job/evaluation",
                    "title": "Evaluation",
                    "type": "link",
                    "code": "view_jobs"
                },
                {
                    "path": "/job/question-set",
                    "title": "Question Set",
                    "type": "link",
                    "code": "view_jobs"
                }
            ]
        },]


    not_yet=true
    admin.forEach((item) => {

        if (item.type=="sub"){
            let children=[]
            item.children.forEach((sub_item)=>{
                if (permissions.includes(sub_item.code)){
                    children.push(sub_item)
                }
            })
            item.children=children
            if (item.children.length!=0){
                if (not_yet) {
                    final_list.data.push(admin_head)
                    not_yet=false
                }
                final_list.data.push(item)
            }


        }else{
            if (permissions.includes(item.code)){
                if (not_yet) {
                    final_list.data.push(admin_head)
                    not_yet=false
                }
                final_list.data.push(item)

            }
        }
    })

    let accounts_head_title=
        {
            "headTitle1": "Finance & Accounts",
            "headTitle2": "Accounts, Finance & Budget",
            "type": "headtitle"
        }
    let accounts=[
        {
            "title": "Accounts",
            "icon": "heart",
            "type": "sub",
            "active": false,
            "children": [
                {
                    "path": "/finance-and-accounts/account/category",
                    "title": "Category",
                    "type": "link",
                    "code": "view_accounts"
                },
                {
                    "path": "/finance-and-accounts/account/group",
                    "title": "Group",
                    "type": "link",
                    "code": "view_accounts"
                },
                {
                    "path": "/finance-and-accounts/account/notes",
                    "title": "Notes",
                    "type": "link",
                    "code": "view_accounts"
                },
                {
                    "path": "/finance-and-accounts/account/labels",
                    "title": "Labels",
                    "type": "link",
                    "code": "view_accounts"
                },
                {
                    "path": "/finance-and-accounts/account/ledger",
                    "title": "Ledger",
                    "type": "link",
                    "code": "view_accounts"
                },

                {
                    "path": "/finance-and-accounts/account/transaction/list",
                    "title": "Transactions",
                    "type": "link",
                    "code": "view_accounts"
                },
                {
                    "path": "/finance-and-accounts/account/trial-balance",
                    "title": "Trial-Balance",
                    "type": "link",
                    "code": "view_accounts"
                },
                {

                    "title": "Income Statement",

                    "active": false,

                    "path": "/finance-and-accounts/account/income-statement/statement",

                    "type": "link",
                    "code": "view_accounts"


                    // "children": [
                    //     {
                    //         "path": "/finance-and-accounts/account/income-statement/structure",
                    //         "title": "Structure",
                    //         "type": "link",
                    //         "code": "view_accounts"
                    //     },
                    // ]

                },
                {

                    "title": "Balance Sheet",

                    "active": false,

                    "path": "/finance-and-accounts/account/balance-sheet/statement",

                    "type": "link",
                    "code": "view_accounts"
                }
            ]
        },
        {
            "title": "Finance",
            "icon": "trello",
            "type": "sub",
            "active": false,
            "children": [
                {
                    "path": "/finance-and-accounts/finance/items/list",
                    "title": "Items",
                    "type": "link",
                    "code": "view_finance"
                },
                {
                    "path": "/finance-and-accounts/finance/quotation/list",
                    "title": "Quotations",
                    "type": "link",
                    "code": "view_finance"
                },

                {
                    "path": "/finance-and-accounts/finance/invoice/list",
                    "title": "Invoices",
                    "type": "link",
                    "code": "view_finance"
                },
                {
                    "path": "/finance-and-accounts/finance/purchase-order/list",
                    "title": "Purchase Orders",
                    "type": "link",
                    "code": "view_finance"
                },
                {
                    "path": "/finance-and-accounts/finance/bill/list",
                    "title": "Bills",
                    "type": "link",
                    "code": "view_finance"
                }
            ]
        },
        {
            "title": "Budget",
            "icon": "layout",
            "type": "sub",
            "active": false,
            "children": [
                {
                    "path": "/finance-and-accounts/budget/estimated-budget/list",
                    "title": "Estimated Budgets",
                    "type": "link",
                    "code": "view_budgets"
                },
                {
                    "path": "/finance-and-accounts/budget/budget-request/list",
                    "title": "Budget Requests",
                    "type": "link",
                    "code": "view_budgets"
                },
                {
                    "path": "/finance-and-accounts/budget/request-for-budget/new",
                    "title": "Request for Budget",
                    "type": "link",
                    "code": "view_budgets"
                }
            ]
        },
        {
            "title": "Payments",
            "icon": "dollar-sign",
            "type": "sub",
            "active": false,
            "children": [
                {
                    "path": "/finance-and-accounts/payments/requisition/list",
                    "title": "Requisition",
                    "type": "link",
                    "code": "view_accounts"
                },
                {
                    "path": "/finance-and-accounts/payments/invoice/list",
                    "title": "Invoice",
                    "type": "link",
                    "code": "view_accounts"
                },
                {
                    "path": "/finance-and-accounts/payments/bill/list",
                    "title": "Bill",
                    "type": "link",
                    "code": "view_accounts"
                }
            ]
        },
        {
            "path": "/finance-and-accounts/banking-info",
            "title": "Banking Info",
            "icon": "credit-card",
            "type": "link",
            "active": false,
            "code": "view_accounts"
        }
    ]


    not_yet=true
    accounts.forEach((item) => {


        if (item.type=="sub"){

            let children=[]
            item.children.forEach((sub_item)=>{
                if (permissions.includes(sub_item.code)){
                    children.push(sub_item)
                }
            })
            item.children=children
            if (item.children.length!=0){
                if (not_yet) {
                    final_list.data.push(accounts_head_title)
                    not_yet=false
                }
                final_list.data.push(item)
            }


        }else{
            if (permissions.includes(item.code)){
                if (not_yet) {
                    final_list.data.push(accounts_head_title)
                    not_yet=false
                }
                final_list.data.push(item)

            }
        }
    })
}

profile_head_title.forEach((profile) => {
    final_list.data.push(profile)
})
export default final_list
