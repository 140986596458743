<template>
<div>
    <div>
        <Breadcrumbs main="" submain="My Workspace" title="My Updates" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row flex-md-row-reverse" v-if="!loading && !error">

                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); margin-top: 8px; gap: 8px;">
                                <div>
                                    <span style="font-size: medium; width: 100%;">
                                        <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="danger" @click="filterByRefType('LEAVE')">
                                            <span>LEAVE</span>
                                        </b-badge>
                                    </span>
                                </div>
                                <div>
                                    <span style="font-size: medium; width: 100%;">
                                        <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="info" @click="filterByRefType('TICKET')">
                                            <span>TICKET</span>
                                        </b-badge>
                                    </span>
                                </div>
                                <div>
                                    <span style="font-size: medium; width: 100%;">
                                        <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="warning" @click="filterByRefType('BUDGET')">
                                            <span>BUDGET</span>
                                        </b-badge>
                                    </span>
                                </div>
                                <div>
                                    <span style="font-size: medium; width: 100%;">
                                        <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="primary" @click="filterByRefType('GRIEVANCE')">
                                            <span>GRIEVANCE</span>
                                        </b-badge>
                                    </span>
                                </div>
                                <div>
                                    <span style="font-size: medium; width: 100%;">
                                        <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="primary" @click="filterByRefType('EOD')">
                                            <span>EOD</span>
                                        </b-badge>
                                    </span>
                                </div>
                                <div>
                                    <span style="font-size: medium; width: 100%;">
                                        <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="secondary" @click="filterByRefType('REQUISITION')">
                                            <span>REQUISITION</span>
                                        </b-badge>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 my-2">
                    <div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <datepicker placeholder="Start Date"  input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                </div>
                                <!-- <b-form-datepicker id="example-datepicker" v-model="start_date" class="mb-2" placeholder="Start Date"></b-form-datepicker> -->
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                  
                                    <datepicker placeholder="End Date"  input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                </div>
                           
                            </div>
                            <div class="col-sm-4">
                                <div class="d-flex flex-row justify-content-center align-items-center" style="gap: 5px;">
                                    <h6>Only show unread</h6>
                                    <div class="media-body text-right icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" :checked="messageBool" @click="toggleShowMessage()">
                                            <span class="switch-state bg-primary">{{ messageReadText }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="card" v-for="myUpdate in visibleUpdates" :key="myUpdate.id" :class="{ 'read': !myUpdate.read }">
                            <div class="card-body" @click="readMsg(myUpdate)" style="cursor: pointer;">
                                <div style="display: flex; flex-direction: row; justify-content: space-between">
                                    <div>
                                        <h7>{{ myUpdate.activity }}</h7>
                                    </div>
                                    <div>
                                        <span v-if="myUpdate.ref_type == 'LEAVE'">
                                            <b-badge pill variant="danger">{{ myUpdate.ref_type}}</b-badge>
                                        </span>
                                        <span v-if="myUpdate.ref_type == 'TICKET'">
                                            <b-badge pill variant="info">{{ myUpdate.ref_type}}</b-badge>
                                        </span>
                                        <span v-if="myUpdate.ref_type == 'BUDGET'">
                                            <b-badge pill variant="warning">{{ myUpdate.ref_type}}</b-badge>
                                        </span>
                                        <span v-if="myUpdate.ref_type == 'GRIEVANCE'">
                                            <b-badge pill variant="primary">{{ myUpdate.ref_type}}</b-badge>
                                        </span>
                                        <span v-if="myUpdate.ref_type == 'EOD'">
                                            <b-badge pill variant="dark">{{ myUpdate.ref_type}}</b-badge>
                                        </span>
                                        <span v-if="myUpdate.ref_type == 'REQUISITION'">
                                            <b-badge pill variant="secondary">{{ myUpdate.ref_type}}</b-badge>
                                        </span>

                                    </div>
                                </div>
                                <div>
                                    <h7>{{ updateTime(myUpdate.timestamp) }}</h7>
                               

                                </div>

                            </div>
                        </div>
                        <div class="d-flex flex-column">
                            <div class="d-flex justify-content-center" v-if="showLoadMoreButton()" style="cursor: pointer;">
                                <button class="btn btn-primary " @click="loadMore()">Load More</button>
                            </div>
                        </div>
                    </div>
                    <div class="card" v-if="visibleUpdates.length <= 0">
                        <div class="card-body">
                            <div class="d-flex justify-content-center">
                                <h6> No Updates Found</h6>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row" v-else>
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- Container-fluid Ends-->
    </div>

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import moment from "moment";
export default {
    components: {
        Multiselect,
        Datepicker
    },

    data() {
        return {
            updatedTime: null,
            visibleMyUpdates: 15,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'activity',
                    label: 'Activity',
                    sortable: true
                },
                {
                    key: 'timestamp',
                    label: 'Time Stamp',
                    sortable: true
                },
                {
                    key: 'ref_type',
                    label: 'Type',
                    sortable: true
                },

            ],
            start_date: null,
            end_date: null,
            messageRead: null,
            messageBool: false,

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    watch: {
        start_date(newVal, oldVal) {
            if (this.start_date !== null) {
                this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");
            }
            this.fetchData();
        },
        end_date(newVal, oldVal) {
            if (this.end_date !== null) {
                this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
            }
            this.fetchData();
        },
        messageBool(newVal, oldVal) {
            this.fetchData();
        }
    },

    computed: {

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        ...mapState({
            updates_list: state => state.myUpdates.my_updates,
            loading: state => state.myUpdates.submitLoading,
            error: state => state.myUpdates.submitError,
            eod_reply: state => state.eod.eod_reply,

        }),

        visibleUpdates() {
          
                return this.updates_list.slice(0, this.visibleMyUpdates);
           
        },

    },

    mounted() {
        // this.$store.dispatch("myUpdates/fetchMyUpdates", {
        //     ref_type: this.filter
        // }).then(() => {
        //     this.items = this.updates_list
        //     this.totalRows = this.items.length;
        // });
        this.fetchData();

    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        async fetchData() {
            let payload = {
                ref_type: this.filter,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.messageRead,

            };
            await this.$store.dispatch('myUpdates/fetchMyUpdates', payload);
        },

        toggleShowMessage() {
            this.messageBool = !this.messageBool;
            if (this.messageBool) {
                this.messageRead = 'False'
            } else {
                this.messageRead = null
            }
        },
        updateTime(timestamp) {
            const date = new Date(timestamp);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;

            // Format time as HH:MM:SS AM/PM
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;

            const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;

            return `${formattedDate } ${formattedTime}`;
        },
        filterByRefType(refType) {
            // this.$store.dispatch("myUpdates/fetchMyUpdates", {
            //     ref_type: refType
            // }).then(() => {
            //     this.items = this.updates_list
            //     this.totalRows = this.items.length;
            // });
            this.visibleMyUpdates = 5;
            this.filter = refType;
            this.fetchData();
        },

        loadMore() {
            this.visibleMyUpdates += 5;
        },

        showLoadMoreButton() {
            return this.visibleUpdates.length < this.updates_list.length;
        },

        readMsg(data) {
            const routes = {
                'LEAVE': `/my-workspace/leave-details/${data.type_id}`,
                'TICKET': `/my-workspace/ticket-details/${data.type_id}`,
                'BUDGET': `/my-workspace/budget-details/${data.type_id}`,
                'GRIEVANCE': `/my-workspace/grievance-details/${data.type_id}`,
                'EOD': `/eod/details/${data.type_id}`,
                'REQUISITION': `/my-workspace/requisition-details/${data.type_id}`
            };

            this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                if (response.code == 200) {
                    this.$router.push(routes[data.ref_type]);
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;

        }
    }
};
</script>

<style scoped>
.read {
    background-color: #fff0f0c7;
}
</style>
