<template>
    <div class="table-container">
      <div class="table-responsive datatable-vue">
        <b-table show-empty stacked="md" @change="emitChange" :items="tableData" :fields="fields">
          <template #cell(id)="row">
            {{ row.index + 1 }}
          </template>
  
          <template #cell(name)="row">
            <input
              class="form-control"
              type="text"
              placeholder="Enter item"
              list="quotation_items"
              @change="getItems(row.item.name, row.index)"
              v-model="row.item.name"
            />
          </template>
  
          <template #cell(description)="row">
            <input class="form-control" type="text" placeholder="Enter description" v-model="row.item.description" />
          </template>
  
          <template #cell(action)="row">
            <button @click="removeItem(row.item.id)" class="btn btn-primary" type="submit">Delete</button>
          </template>
        </b-table>
      </div>
  
      <div>
        <b-button variant="primary" @click="addItem">Add Item</b-button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ItemTable",
    props: {
      tableData: {
        type: Array,
        default: () => [
          {
            id: 1,
            name: null,
            description: null,
          },
        ],
      },
    },
    data() {
      return {
        fields: [
          { key: "id", label: "#" },
          { key: "name", label: "Item", sortable: false },
          { key: "description", label: "Description", sortable: false },
          { key: "action", label: "Action", sortable: false },
        ],
        quotation_items: [],
      };
    },
    computed: {
      currentId() {
        return this.tableData.length;
      },
    },
    methods: {
      addItem() {
        this.tableData.push({
          id: this.currentId + 1,
          name: null,
          description: null,
        });
      },
      getItems(name, id) {
        const item = this.quotation_items.find((item) => item.name === name);
        if (item) {
          this.tableData[id].name = item.name;
          this.tableData[id].description = item.description;
        }
        this.calculateId();
        this.emitChange();
      },
      removeItem(id) {
        this.tableData = this.tableData.filter((item) => item.id !== id);
        this.calculateId();
        this.emitChange();
      },
      calculateId() {
        this.tableData.forEach((item, index) => {
          item.id = index + 1;
        });
      },
      emitChange() {
        this.$emit("itemsUpdated", this.tableData);
      },
    },
    mounted() {
      this.$store.dispatch("item/fetchItem").then((result) => {
        this.quotation_items = result.data;
      });
    },
  };
  </script>
  
  <style scoped>
  .table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  </style>