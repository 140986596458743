<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Transactions" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Transaction Analysis</h5>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Amount</label>
                                    <b-form-input id="name-input" type="number" v-model="transaction.amount" placeholder="Amount" readonly></b-form-input>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Date</label>
                                    <datepicker placeholder="Entry Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="transaction.entry_date"></datepicker>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Label</label>
                                    <b-form-select v-model="transaction.transaction_label" :options="formattedDepartment"></b-form-select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <h6>Debit Account</h6>

                                <div class="row" v-for="(debit, index) in transaction.debit_instances" :key="index">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Category</label>
                                            <b-form-select v-model="debit.category" @change="updateGroup(debit.category, true)">
                                                <b-form-select-option v-for="option in accountCategories" :value="option.id">{{ option.title }}</b-form-select-option>
                                            </b-form-select>
                                        </div>
                                    </div>

                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Group</label>
                                            <!-- <input class='form-control' type="text" list="debit_groups" v-model="debit.group" @change="updateLedgers(debit.group, true)" /> -->
                                            <!--<input class='form-contro' type="text" list="debit_ledgers" v-model="debit.ledger_name"/>-->
                                            <!-- <datalist id="debit_groups">
                                                <option v-for="option in debit_groups" :value="option.title">{{ option.title }}</option>
                                            </datalist> -->
                                            <b-form-select v-model="debit.group" @change="updateLedgers(debit.group, true)">
                                                <b-form-select-option v-for="option in debit_groups" :value="option.id">{{ option.title }}</b-form-select-option>
                                            </b-form-select>
                                        </div>
                                    </div>

                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Ledger</label>
                                            <!-- <input class='form-control' type="text" list="debit_ledgers" v-model="debit.ledger" /> -->
                                            <!--<input class='form-contro' type="text" list="debit_ledgers" v-model="debit.ledger_name"/>-->
                                            <!-- <datalist id="debit_ledgers">
                                                <option v-for="option in debit_ledgers" :value="option.title">{{ option.title }}</option>
                                            </datalist> -->
                                            <b-form-select v-model="debit.ledger">
                                                <b-form-select-option v-for="option in debit_ledgers" :value="option.id">{{ option.title }}</b-form-select-option>
                                            </b-form-select>
                                        </div>
                                    </div>

                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>Amount</label>
                                            <b-form-input id="name-input" type="number" v-model="debit.amount" placeholder="Enter debit amount"></b-form-input>
                                        </div>
                                    </div>

                                    <div class="col-sm-1" style="margin-top: 35px;">
                                        <!-- Add Svg Icon -->
                                        <svg @click="addField(debit.id, transaction.debit_instances, true)" width="24" height="24" style="cursor: pointer;">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                        </svg>

                                        <!-- Remove Svg Icon -->
                                        <svg v-if="transaction.debit_instances.length > 1 && !debit.id" @click="removeField(index, transaction.debit_instances)" width="24" height="24" style="cursor: pointer;">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <h6>Credit Account</h6>

                                <div class="row" v-for="(credit, index) in transaction.credit_instances" :key="index">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Category</label>
                                            <b-form-select v-model="credit.category" @change="updateGroup(credit.category)">
                                                <b-form-select-option v-for="option in accountCategories" :value="option.id">{{ option.title }}</b-form-select-option>
                                            </b-form-select>
                                        </div>
                                    </div>

                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Group</label>
                                            <!-- <input class='form-control' type="text" list="credit_groups" v-model="credit.group" @change="updateLedgers(credit.group, false)" /> -->
                                            <!--<input class='form-contro' type="text" list="debit_ledgers" v-model="debit.ledger_name"/>-->
                                            <!-- <datalist id="credit_groups">
                                                <option v-for="option in credit_groups" :value="option.title">{{ option.title }}</option>
                                            </datalist> -->
                                            <b-form-select v-model="credit.group" @change="updateLedgers(credit.group, false)" >
                                                <b-form-select-option v-for="option in credit_groups" :value="option.id">{{ option.title }}</b-form-select-option>
                                            </b-form-select>
                                        </div>
                                    </div>

                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Ledger</label>
                                            <!-- <input class='form-control' type="text" list="credit_ledgers" v-model="credit.ledger" /> -->
                                            <!-- <datalist id="credit_ledgers">
                                                <option v-for="option in credit_ledgers" :value="option.title">{{ option.title }}</option>
                                            </datalist> -->
                                            <b-form-select v-model="credit.ledger" >
                                                <b-form-select-option v-for="option in credit_ledgers" :value="option.id">{{ option.title }}</b-form-select-option>
                                            </b-form-select>
                                        </div>
                                    </div>

                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>Amount</label>
                                            <b-form-input id="name-input" type="number" v-model="credit.amount" placeholder="Enter credit amount"></b-form-input>
                                        </div>
                                    </div>

                                    <div class="col-sm-1" style="margin-top: 35px;">
                                        <!-- Add Svg Icon -->
                                        <svg @click="addField(credit.id, transaction.credit_instances, false)" width="24" height="24" style="cursor: pointer;">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                        </svg>

                                        <!-- Remove Svg Icon -->
                                        <svg v-if="transaction.credit_instances.length > 1 && !credit.id" @click="removeField(index, transaction.credit_instances)" width="24" height="24" style="cursor: pointer;">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Note</label>
                                    <b-form-textarea id="textarea-auto-height" v-model="transaction.note" rows="3" max-rows="8" placeholder="Enter note..."></b-form-textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div style="width: 100%; text-align: center;">
                                    <b-button variant="primary" @click="handleSubmit" :disabled="isButtonDisabled">Submit</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h5>Transaction History</h5>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-wrap" style="gap: 20px">
                                    <b-input-group class="datatable-btn" style="width: 340px;">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                        <feather type="filter"></feather>
                                        <h7>Filter</h7>
                                    </div>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="start_date || end_date || statusSelected">
                                        <feather type="x"></feather>
                                        <h7>Clear All</h7>
                                    </div>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!accountCategoryLoading && !accountCategoryError">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" sticky-header :items="items" :fields="tablefields" :per-page="perPage">
                                    <template #cell(index)="data">
                                        {{ data.item.journal_id}}
                                    </template>

                                    <template #cell(entry_date)="data">
                                        {{ data.item.entry_date?data.item.entry_date.split('T')[0]:"N/A" }}<br>
                                        <small class="text-bolder">Created @ {{ data.item.date?(data.item.date.split('T')[0]+ "("+data.item.date.split('T')[1].split(".")[0]+")"):"N/A" }}</small>
                                    </template>

                                    <template #cell(ledger_break_down)="data">
                                        <span style="display: flex; flex-direction: column; ">
                                            <span v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column">{{ledger.ledger_id.title}}
                                                <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>
                                        </span>
                                        <span style="display: flex; flex-direction: column;">
                                            <span v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display: flex; flex-direction: column">
                                                <span style="margin-left: 16px;">{{ledger.ledger_id.title}}</span>
                                                <span v-if="data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>
                                        </span>
                                    </template>

                                    <template #cell(reference)="data">
                                        <span style="display: flex; flex-direction: column;">
                                            <span v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column">
                                                <router-link :to="{ path: '/finance-and-accounts/account/ledger/' + ledger.ledger_id.lid }">
                                                    {{ ledger.ledger_id.lid }}
                                                </router-link>
                                                <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>
                                            <span v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display: flex; flex-direction: column">
                                                <router-link :to="{ path: '/finance-and-accounts/account/ledger/' + ledger.ledger_id.lid }">
                                                    {{ ledger.ledger_id.lid}}
                                                </router-link>
                                                <span v-if=" data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>
                                        </span>
                                    </template>

                                    <template #cell(debit)="data">
                                        <span style="display: flex; flex-direction: column;">
                                            <span v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column;">
                                                {{ ledger.amount }}
                                                <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>
                                            <span v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display:flex;flex-direction: column">
                                                <br>
                                                <span v-if=" data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>
                                        </span>
                                    </template>

                                    <template #cell(credit)="data">
                                        <span style="display: flex; flex-direction: column;">
                                            <span v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column;">
                                                <br>
                                                <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>
                                            <span v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display: flex; flex-direction: column;">
                                                {{ ledger.amount }}
                                                <span v-if=" data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>
                                        </span>
                                    </template>

                                    <template #cell(amount)="data">
                                        <span style="display: flex; flex-direction: column;">
                                            {{ data.item.amount }}
                                        </span>
                                    </template>

                                    <template #cell(note)="data">
                                        <small v-if="data.item.transaction_label" class="round-badge-primary px-1">{{ data.item.transaction_label.title }}<br></small>
                                        <span>
                                            {{ data.item.note }}
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <div @click="editNote(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit note" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Note Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit note" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Date" label-for="date-input">
                <datepicker placeholder="Entry Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="edit_note.entry_date"></datepicker>
            </b-form-group>
            <b-form-group label="Note" label-for="name-input">
                <b-form-textarea id="textarea-auto-height" v-model="edit_note.note" rows="3" max-rows="8" placeholder="Enter note..."></b-form-textarea>
            </b-form-group>
            <b-form-group label="Label" label-for="label-input">
                <b-form-select v-model="edit_note.transaction_label" :options="formattedDepartment"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapState } from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment';

export default {
    components: {
        Datepicker
    },

    data() {
        return {
            isButtonDisabled: false,
            statusSelected: '',
            statuses: [
                {
                    id: 1,
                    name: 'Created Date',
                    value: 'date'
                },
                {
                    id: 2,
                    name: 'Entry Date',
                    value: 'entry_date'
                }
            ],
            tablefields: [
                {
                    key: 'index',
                    label: '#',
                    sortable: true
                },
                {
                    key: 'entry_date',
                    label: 'Entry Date',
                    sortable: true
                },
                {
                    key: 'ledger_break_down',
                    label: 'Account Title',
                    sortable: true
                },
                {
                    key: 'reference',
                    label: 'Reference',
                },
                {
                    key: 'debit',
                    label: 'Debit',
                },
                {
                    key: 'credit',
                    label: 'Credit',
                },
                {
                    key: 'amount',
                    label: 'amount',
                },
                {
                    key: 'note',
                    label: 'Note',
                },
                {
                    key: 'action',
                    label: 'Action',
                }
            ],

            items: [],
            edit_note: {
                id: null,
                note: '',
                entry_date: '',
                transaction_label: null
            },

            credit_groups: [],
            credit_ledgers: [],
            debit_groups: [],
            debit_ledgers: [],
            transaction_labels: [],

            transaction: {
                date: '',
                entry_date: '',
                transaction_label: null,
                debit_instances: [
                    {
                        category: null,
                        group: null,
                        ledger: null,
                        amount: null,
                        is_debit: true
                    }
                ],
                credit_instances: [
                    {
                        category: null,
                        group: null,
                        ledger: null,
                        amount: null,
                        is_debit: false
                    }
                ],
                amount: 0,
                note: ''
            },
            debitamount: 0,
            creditamount: 0,

            initialLoad: true,
            isModifyingDate: false,
            filterShow: false,
            start_date: null,
            end_date: null,
            filter: "",

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState('account', ["accountTransactionsPG", "accountLabels", "accountTransactions_count", "accountTransactionsLoading", "accountTransactionsError", "accountCategories", "accountGroups", "accountLedgers", "accountLedgerLoading", "accountLedgerError", "accountGroupLoading", "accountGroupError", "accountCategoryLoading", "accountCategoryError", "submitError", "submitSuccess", "submitLoading"]),
       
        formattedOptions() {
            return [
                {
                    value: '',
                    text: 'Select Date Type',
                    disabled: true
                },
                ...this.statuses.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        },

        totalDebitAmount() {
            return this.transaction.debit_instances.reduce((total, debit) => {
                return total + (parseFloat(debit.amount) || 0);
            }, 0);
        },

        totalCreditAmount() {
            return this.transaction.credit_instances.reduce((total, credit) => {
                return total + (parseFloat(credit.amount) || 0);
            }, 0);
        },

        formattedDepartment() {
            return [
                {
                    value: '',
                    text: 'Select Label',
                    disabled: true
                },
                ...this.accountLabels.map(label => ({
                    value: label.id,
                    text: label.title
                }))
            ];
        },
    },

    created() {
        this.$store.dispatch('account/fetchTransactionLabel').then(result => {
            this.transaction_labels = this.accountLabels
        })
        this.$store.dispatch('account/fetchAccountCategory')
        this.$store.dispatch('account/fetchAccountGroup').then(result => {
            this.debit_groups = this.accountGroups
            this.credit_groups = this.accountGroups
        })
        this.$store.dispatch('account/fetchAccountLedger').then(result => {
            this.debit_ledgers = this.accountLedgers
            this.credit_ledgers = this.accountLedgers
        })
        this.transaction.entry_date = new Date()
    },

    watch: {
        totalCreditAmount(newValue) {
            this.creditamount = newValue;
            this.updateTransactionAmount();
        },
        totalDebitAmount(newValue) {
            this.debitamount = newValue;
            this.updateTransactionAmount();
        },
        accountCategories(oldValue, newValue) {},
        accountTransactionsPG(oldValue, newValue) {
            this.items = this.accountTransactionsPG
            this.totalRows = this.accountTransactions_count
        },
        accountLedgers(oldValue, newValue) {},
        accountGroups(oldValue, newValue) {},

        start_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.start_date !== null) {
                        this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");

                        this.$store.dispatch("account/fetchAccountTransactionsWithPG", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.statusSelected
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.accountTransactions_count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("account/fetchAccountTransactionsWithPG", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.statusSelected
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.accountTransactions_count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }
            }
        },

        end_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.end_date !== null) {
                        this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");

                        this.$store.dispatch("account/fetchAccountTransactionsWithPG", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.statusSelected
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.accountTransactions_count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("account/fetchAccountTransactionsWithPG", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.statusSelected
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.accountTransactions_count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }
            }
        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/account/transaction/list',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("account/fetchAccountTransactionsWithPG", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected
                }).then(() => {
                    this.totalRows = this.accountTransactions_count;
                    this.initialLoad = false;  
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/account/transaction/list',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("account/fetchAccountTransactionsWithPG", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected
                }).then(() => {
                    this.totalRows = this.accountTransactions_count;
                    this.initialLoad = false;
                });
            }
        },

        filter(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("account/fetchAccountTransactionsWithPG", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.accountTransactions_count;
                this.initialLoad = false;
            });
            }
        },

        statusSelected(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("account/fetchAccountTransactionsWithPG", {
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.accountTransactions_count;
                    this.initialLoad = false;
                });
            }
        }
    },

    mounted() {
        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {
                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });
                this.fetchInitial();
            } else {
                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);
                this.fetchInitial();
            }
        }
    },

    methods: {
        fetchInitial() {
            this.$store.dispatch("account/fetchAccountTransactionsWithPG", {
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected
            }).then(() => {
                this.totalRows = this.accountTransactions_count;
                this.initialLoad = false;
            });
        },

        clearFilter() {
            this.filterShow = false,
            this.filter = null,
            this.start_date = null,
            this.end_date = null,
            this.statusSelected = ''
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        updateTransactionAmount() {
            if (this.debitamount === this.creditamount) {
                this.transaction.amount = this.debitamount;
            } else {
                this.transaction.amount = 0;
            }
        },

        updateGroup(id, is_debit) {
            this.$store.dispatch('account/fetchAccountGroupByCategory', id).then(result => {
                if (is_debit) {
                    this.debit_groups = this.accountGroups
                } else {
                    this.credit_groups = this.accountGroups
                }
            })
        },

        updateLedgers(id, is_debit) {
            this.$store.dispatch('account/fetchAccountLedgerByGroup', id).then(result => {
                if (is_debit) {
                    this.debit_ledgers = this.accountLedgers
                } else {
                    this.credit_ledgers = this.accountLedgers
                }
            })
        },

        addField(value, fieldType, is_debit) {
            fieldType.push({
                is_debit: is_debit
            });
        },

        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        handleSubmit() {
            if (this.debitamount != 0 && this.creditamount != 0) {
                if (this.debitamount === this.creditamount) {
                    if (!this.isButtonDisabled) {
                        this.isButtonDisabled = true;
                        this.$store.dispatch("account/recordTransaction", {
                            transaction: this.transaction,
                            currentPage: this.$route.query.page,
                            perPage: this.$route.query.perPage,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.statusSelected
                        }).then(response => {
                            if (response.code == 200) {
                                this.isButtonDisabled = false;
                                Swal.fire({
                                    title: "Success!",
                                    text: response.response,
                                    icon: "success"
                                });
                                this.transaction = {
                                    date: '',
                                    entry_date: new Date(),
                                    debit_instances: [{
                                        category: null,
                                        group: null,
                                        ledger_id: null,
                                        amount: null,
                                        is_debit: true
                                    }],
                                    credit_instances: [{
                                        category: null,
                                        group: null,
                                        ledger_id: null,
                                        amount: null,
                                        is_debit: false
                                    }],
                                    amount: '',
                                    note: ''
                                }
                            } else {
                                this.isButtonDisabled = false;
                                Swal.fire({
                                    title: "Error!",
                                    text: response.response,
                                    icon: "error"
                                });
                            }
                        })
                    }
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: 'Debit amount must be equal to credit amount.',
                        icon: "error"
                    });
                }
            } else {
                Swal.fire({
                    title: "Error!",
                    text: 'Please enter valid amount',
                    icon: "error"
                });
            }
        },

        editNote(type) {
            this.edit_note.id = type.journal_id
            this.edit_note.note = type.note
            this.edit_note.entry_date = type.entry_date
            this.edit_note.transaction_label = type.transaction_label ? type.transaction_label.id : null
        },

        resetEditModal() {
            this.edit_note.id = null
            this.edit_note.note = null
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {
            this.$store.dispatch("account/updateAccountTransactions", {
                id: this.edit_note.id,
                note: this.edit_note.note,
                entry_date: this.edit_note.entry_date,
                transaction_label: this.edit_note.transaction_label,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.edit_item.phone = '',
                    this.edit_item.company = '',
                    this.edit_item.address = ''
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        }
    }
};
</script>
