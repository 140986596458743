<template>
<div>
    <Breadcrumbs main="HR" title="Asset Edit" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-8">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Asset Name <span style="color: red;">*</span></label>
                                        <input class="form-control" type="text" placeholder="Enter asset name" v-model="temp_asset.name">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Amount <span style="color: red;">*</span></label>
                                        <input class="form-control" type="number" placeholder="Enter asset amount" v-model="temp_asset.amount">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Status <span style="color: red;">*</span></label>
                                        <select class="form-control digits" v-model="temp_asset.status">
                                            <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Manufacture Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="temp_asset.manufacture_date"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Asset Type</label>
                                        <select class="form-control digits" v-model="temp_asset.asset_type">
                                            <option v-for="(type, index) in type" :key="index" :value="type.id">{{ type.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Purchase Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="temp_asset.purchase_date"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <!-- <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Warranty</label>
                                        <div class="input-group">
                                            <input class="form-control" type="number" v-model="temp_asset.warranty">
                                            <select class="custom-select" v-model="temp_asset.warranty_type" @change="calculateWarrantyEnd()">
                                                <option v-for="(warranty, index) in warrantyUnit" :key="index">{{ warranty }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Warranty End</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="temp_asset.warranty_end"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Items</label>
                                        <ItemTable v-if="temp_asset.specification" id="item-table" style="margin-bottom: 10px;" :tableData="temp_asset.specification" @itemsUpdated="updateItems"  />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Upload</label>
                                        <div class="flex items-center">
                                            <img v-if="show_image" style="height: 100px; width: 100px; object-fit: cover;" :src="show_image">
                                            <input :class="temp_asset.image ? 'ml-4' : 'ml-4'" type="file" accept="image/*" @change="uploadFile">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Asset Details</label>
                                        <b-form-textarea id="textarea-auto-height" placeholder="Enter asset details..." v-model="temp_asset.details" rows="4" max-rows="13"></b-form-textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit()" class="btn btn-success mr-3">Submit</button>
                                        <router-link to="/hr/asset-management/asset/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </px-card>
            </div>

            <div class="col-sm-4">
                <px-card>
                    <div class="w-100 p-3">
                        <div class="d-flex justify-content-between">
                            <h6 class="mb-3">Supporting Documents</h6>
                            <feather type="plus-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-modal.modal-scrollable-add-document v-b-tooltip.hover title="Add Documents"> </feather>
                        </div>

                        <div>
                            <b-table show-empty sticky-header :items="asset.asset_attach" :fields="Attachment" head-variant="light">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template #cell(title)="data">
                                    <a :href="host+data.item.asset_attachment" target="_blank">{{ truncateText(data.item.attach_title, 100) }}</a>
                                </template>

                                <template #cell(action)="data">
                                    <div style="display: flex; flex-direction: row; gap:10px">
                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete" @click="deleteAttach(data.item.id)"></feather>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>

    <!-- Project Attachment Create -->
    <b-modal id="modal-scrollable-add-document" ref="modal" title="Add document" @ok="handleAttachOk">
        <form ref="form" @submit.stop.prevent="handleAttahmentSubmit">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Upload Documents</label>
                        <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." @change="uploadAttachFile"></b-form-file>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { myHost } from "../../../../_helpers/host";
import { mapState } from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";
import ItemTable from "./edit-item-table.vue";

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        ItemTable
    },

    data() {
        return {
            host: myHost.host,

            add_attachment: {
                asset_attachment: null,
                asset_id: '',
            },

            temp_asset: {
                name: null,
                amount: null,
                purchase_date: null,
                // warranty: '',
                // warranty_type: '',
                asset_type: null,
                details: null,
                manufacture_date: null,
                warranty_end: null,
                status: null,
                image: null,
                specification: []
            },

            warrantyUnit: ['YEAR', 'MONTH'],
            Attachment: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                }
            ],

            show_image: null,
            statuses: ['PENDING', 'APPROVED', 'RETURNED']
        };
    },
    computed: {
        ...mapState({
            asset: state => state.asset.asset_detail,
            type: state => state.asset.asset_type,
        })
    },

    mounted() {
        this.$store.dispatch('asset/fetchAssetDetails', this.$route.params.id)
        this.$store.dispatch("asset/fetchAssetType")
    },

    watch: {
        asset(oldValue, newValue) {
            this.temp_asset.name = this.asset.name ?  this.asset.name : null
            this.temp_asset.amount = this.asset.amount ? this.asset.amount : null
            this.temp_asset.purchase_date = this.asset.purchase_date ? this.asset.purchase_date : null
            // this.temp_asset.warranty = this.asset.warranty
            this.temp_asset.details = this.asset.details ? this.asset.details : null
            this.temp_asset.manufacture_date = this.asset.manufacture_date ? this.asset.manufacture_date : null
            this.temp_asset.warranty_end = this.asset.warranty_end ? this.asset.warranty_end : null
            this.temp_asset.status = this.asset.status ? this.asset.status : null
            // this.temp_asset.warranty_type = this.asset.warranty_type
            this.temp_asset.asset_type = this.asset.asset_type ? this.asset.asset_type.id : null
            this.temp_asset.specification = this.asset.specification ? JSON.parse(this.asset.specification) : []
            this.show_image = this.host + this.asset.image
        },

        // 'temp_asset.purchase_date': function () {
        //     this.calculateWarrantyEnd();
        // },

        // 'temp_asset.warranty': function () {
        //     this.calculateWarrantyEnd();
        // },

        // 'warrantyUnit': function () {
        //     this.calculateWarrantyEnd();
        // }
    },

    methods: {
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },

        updateItems(data) {
            console.log(data)
            if (data) {
                this.temp_asset.specification = data;
            }
        },

        // calculateWarrantyEnd() {
        //     if (this.temp_asset.purchase_date && this.temp_asset.warranty !== '') {
        //         const purchaseDate = moment(this.asset.purchase_date);
        //         const years = this.temp_asset.warranty_type === 'YEAR' ? this.temp_asset.warranty : 0;
        //         const months = this.temp_asset.warranty_type === 'MONTH' ? this.temp_asset.warranty : 0;
        //         const warrantyEnd = purchaseDate.clone().add(years, 'YEAR').add(months, 'MONTH');
        //         this.temp_asset.warranty_end = warrantyEnd.format('YYYY-MM-DD');
        //     }
        // },

        handleAttachOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleAttahmentSubmit()
        },

        handleAttahmentSubmit() {
            this.add_attachment.asset_id = this.$route.params.id
            this.$store.dispatch("asset/addAssetAttachment", {
                attachment: this.add_attachment,
                asset_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.add_attachment.requisition_attachment = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable-add-document')
            })
        },

        deleteAttach(id) {
            this.$store.dispatch("asset/deleteAttachmentFromAsset", {
                id: id,
                asset_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.delete_value = null
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        uploadAttachFile(e) {
            this.add_attachment.asset_attachment = e.target.files[0]
        },

        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.temp_asset.image = e.target.result;
                this.show_image = e.target.result;
            };
        },

        onSubmit() {
            if (this.temp_asset.warranty_end !== null) {
                this.temp_asset.warranty_end = moment(String(this.temp_asset.warranty_end)).format("YYYY-MM-DD");
            }
            if (this.temp_asset.manufacture_date !== null) {
                this.temp_asset.manufacture_date = moment(String(this.temp_asset.manufacture_date)).format("YYYY-MM-DD");
            }
            if (this.temp_asset.purchase_date !== null) {
                this.temp_asset.purchase_date = moment(String(this.temp_asset.purchase_date)).format("YYYY-MM-DD");
            }

            if( this.temp_asset.name && this.temp_asset.amount && this.temp_asset.status) {
                this.$store.dispatch("asset/updateAsset", {
                    id: this.$route.params.id,
                    payload: this.temp_asset
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.temp_asset.name = null
                        this.temp_asset.amount = null
                        this.temp_asset.purchase_date = null
                        // this.temp_asset.warranty = null
                        this.temp_asset.details = null
                        this.temp_asset.manufacture_date = null
                        this.temp_asset.warranty_end = null
                        this.temp_asset.status = null
                        this.temp_asset.image = null
                        this.temp_asset.asset_type = null
                        // this.temp_asset.warranty_type = null
                        setTimeout(() => {
                            this.$router.push('/hr/asset-management/asset/list')
                        }, 3000)
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            } else {
                Swal.fire({
                    title: "Error!",
                    text: 'You must enter all the mandatory fields (*)',
                    icon: "error"
                });
            }
        }
    }
};
</script>

<style scoped>
.content-container-attahment {
    background-color: white;
    padding: 10px;
}
</style>
