<template>
    <div>
        <Breadcrumbs main="Profile" title="Change Password"/>

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="edit-profile">
                <div class="row">
                    <div class="col-xl-4"></div>

                    <div class="col-xl-4"> 
                        <div class="card">
                            <!--              <div class="card-header">-->
                            <!--&lt;!&ndash;                <h4 class="card-title mb-0">Change Password</h4>&ndash;&gt;-->
                            <!--                <div class="card-options"><a class="card-options-collapse" href="#" data-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="#" data-toggle="card-remove"><i class="fe fe-x"></i></a></div>-->
                            <!--              </div>-->
                            <div class="card-body">
                                    <div class="form-group">
                                        <label class="form-label">Old Password</label>
                                        <div style="position: relative; width: 100%;">
                                            <div style="position: absolute; top: 0px; bottom: 0px; right: 0px; padding-right: 28px; display: flex; align-items: center;">
                                                <i style="position: absolute; display: flex; justify-content: center; cursor: pointer;" :class="passwordToggleIcon1" @click="togglePasswordVisibility1" />
                                            </div>
                                            <input class="form-control" :type="passwordFieldType1" v-model="password.old_password" placeholder="*********">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">New Password</label>
                                        <div style="position: relative; width: 100%;">
                                            <div style="position: absolute; top: 0px; bottom: 0px; right: 0px; padding-right: 28px; display: flex; align-items: center;">
                                                <i style="position: absolute; display: flex; justify-content: center; cursor: pointer;" :class="passwordToggleIcon2" @click="togglePasswordVisibility2" />
                                            </div>
                                            <input class="form-control" :type="passwordFieldType2" v-model="password.new_password1" placeholder="*********">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Re-Type New Password</label>
                                        <div style="position: relative; width: 100%;">
                                            <div style="position: absolute; top: 0px; bottom: 0px; right: 0px; padding-right: 28px; display: flex; align-items: center;">
                                                <i style="position: absolute; display: flex; justify-content: center; cursor: pointer;" :class="passwordToggleIcon3" @click="togglePasswordVisibility3" />
                                            </div>
                                            <input class="form-control" :type="passwordFieldType3" v-model="password.new_password2" placeholder="*********">
                                        </div>
                                    </div>

                                    <div class="form-footer">
                                        <button class="btn btn-primary btn-block" @click="updatePassword">Update Password</button>
                                    </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import UserAuth from '../../auth/js/index';
// import UserAuth from '../../auth/js/index';

export default {
    data() {
        return {
            passwordFieldType1: 'password',
            passwordFieldType2: 'password',
            passwordFieldType3: 'password',
            password: {
                old_password: '',
                new_password1: '',
                new_password2: ''
            },
        };
    },

    computed: { 
        passwordToggleIcon1() {
            return this.passwordFieldType1 === 'password' ? 'fa fa-regular fa-eye' : 'fa fa-regular fa-eye-slash'
        },

        passwordToggleIcon2() {
            return this.passwordFieldType2 === 'password' ? 'fa fa-regular fa-eye' : 'fa fa-regular fa-eye-slash'
        },

        passwordToggleIcon3() {
            return this.passwordFieldType3 === 'password' ? 'fa fa-regular fa-eye' : 'fa fa-regular fa-eye-slash'
        }
    },

    methods: {
        togglePasswordVisibility1() {
            this.passwordFieldType1 = this.passwordFieldType1 === 'password' ? 'text' : 'password'
        },

        togglePasswordVisibility2() {
            this.passwordFieldType2 = this.passwordFieldType2 === 'password' ? 'text' : 'password'
        },

        togglePasswordVisibility3() {
            this.passwordFieldType3 = this.passwordFieldType3 === 'password' ? 'text' : 'password'
        },

        updatePassword() { 
            const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

            if (this.password.new_password1 !== this.password.new_password2) {
                Swal.fire({
                    title: "Error!",
                    text: "Passwords don't match",
                    icon: "error"
                });
            } else if (this.password.new_password1.length < 8) {
                Swal.fire({
                    title: "Error!",
                    text: "Password must be at least 8 characters long",
                    icon: "error"
                });
            } else if (!specialCharacterRegex.test(this.password.new_password1)) {
                Swal.fire({
                    title: "Error!",
                    text: "Password must contain at least one special character",
                    icon: "error"
                });
            } else {
                this.$store.dispatch("resetPassword/updatePassword", this.password).then(response => {
                    if(response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.password.old_password = '',
                        this.password.new_password1 = '',
                        this.password.new_password2 = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }
        }
    }
};
</script>
