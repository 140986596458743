<template>
<div>
    <Breadcrumbs main="HR" title="Policy Create" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Title <span style="color: red;">*</span></label>
                                        <input class="form-control" type="text" placeholder="Enter policy title" v-model="policy.title">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Policy <span style="color: red;">*</span></label>
                                        <!-- <b-form-textarea id="textarea-auto-height" rows="3" max-rows="8" placeholder="Enter policy details..." v-model="policy.details"></b-form-textarea> -->
                                        <vue-editor v-model="policy.details"></vue-editor>
                                        <!-- <ckeditor :editor="editor" placeholder="Enter policy details..." v-model="policy.details"></ckeditor> -->
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3" :disabled="isDisabled">Create</button>
                                        <router-link to="/hr/policy">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import treeVue from '../../../advance/tree.vue';
import { VueEditor } from "vue2-editor";

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        VueEditor
    },

    data() {
        return {
            isDisabled:false,
            editor: ClassicEditor,
            policy: {
                title: '',
                details: ''
            }
        };
    },

    methods: {
        onSubmit() {
            this.isDisabled = true

            this.policy.title = this.policy.title.trim() ? this.policy.title : null;
            this.policy.details = this.policy.details.trim() ? this.policy.details : null;

            if(this.policy.title && this.policy.details) {
                this.$store.dispatch("policy/addPolicy", this.policy).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.policy.title = '',
                        this.policy.details = ''

                        setTimeout(() => {
                            this.$router.push('/hr/policy')
                        }, 2000)

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            } else {
                this.policy.title = '',
                this.policy.details = ''
                Swal.fire({
                    title: "Error!",
                    text: 'You must enter all the mandatory fields (*)',
                    icon: "error"
                });
            }
            this.isDisabled = false
        }
    }
};
</script>
