<template>
<div>
    <!-- page-wrapper Start-->
    <div class="container-fluid p-0">
        <div class="login-card">
            <px-card>
                <div style="padding: 28px;">
                    <div class="theme-form">
                        <div class="vendor-width">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>First Name <span style="color: red;">*</span></label>
                                        <input class="form-control" type="text" placeholder="Enter first name" v-model="vendor.first_name">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Last Name <span style="color: red;">*</span></label>
                                        <input class="form-control" type="text" placeholder="Enter last name" v-model="vendor.last_name">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="form-group">
                                <label>Email <span style="color: red;">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter your email address" v-model="vendor.email">
                            </div>
                        </div>

                        <div>
                            <div class="form-group">
                                <label>Phone Number <span style="color: red;">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter your phone number" v-model="vendor.phone">
                            </div>
                        </div>

                        <div>
                            <div class="form-group">
                                <label>Company <span style="color: red;">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter your company name" v-model="vendor.company">
                            </div>
                        </div>

                        <div>
                            <div class="form-group">
                                <label>Password <span style="color: red;">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter your password" v-model="vendor.password">
                            </div>
                        </div>

                        <div>
                            <div class="form-group">
                                <label>Address</label>
                                <input class="form-control" type="text" placeholder="Enter your address" v-model="vendor.address">
                            </div>
                        </div>

                        <div>
                            <div class="form-group">
                                <button @click="onSubmit" class="btn btn-success mr-3">Register</button>
                                <button @click="goBack()" class="btn btn-danger" href="#">Go Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </px-card>
        </div>
    </div>
</div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            vendor: {
                first_name: '',
                last_name: '',
                company: '',
                address: null,
                phone: '',
                email: '',
                password: '',
            }
        };
    },

    methods: {

        onSubmit() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!this.vendor.email || !emailPattern.test(this.vendor.email)) {
                Swal.fire({
                    title: "Error!",
                    text: "Please enter a valid email address",
                    icon: "error"
                });
                return;
            }

            if (!this.vendor.password || this.vendor.password.length < 6) {
                Swal.fire({
                    title: "Error!",
                    text: "Password must be at least 6 characters long",
                    icon: "error"
                });
                return;
            }

            this.vendor.first_name = this.vendor.first_name.trim() ? this.vendor.first_name : null;
            this.vendor.last_name = this.vendor.last_name.trim() ? this.vendor.last_name : null;
            this.vendor.email = this.vendor.email.trim() ? this.vendor.email : null;
            this.vendor.password = this.vendor.password.trim() ? this.vendor.password : null;
            this.vendor.phone = this.vendor.phone.trim() ? this.vendor.phone : null;
            this.vendor.company = this.vendor.company.trim() ? this.vendor.company : null;

            if(this.vendor.first_name && this.vendor.last_name && this.vendor.email && this.vendor.phone && this.vendor.company && this.vendor.password) {
                this.$store.dispatch("vendor/addVendor", {item: this.vendor}).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.vendor.first_name = '',
                        this.vendor.last_name = '',
                        this.vendor.company = '',
                        this.vendor.address = null,
                        this.vendor.phone = '',
                        this.vendor.email = ''
                        this.vendor.password = ''
                        setTimeout(() => {
                            if (this.$route.query.redirect) {
                                this.$router.push({
                                    path: '/auth/login',
                                    query: {
                                        redirect: this.$route.query.redirect
                                    }
                                })
                            } else {
                                this.$router.push('/auth/login')
                            }
                        }, 2000)
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        })
                    }
                })
            } else {
                this.vendor.first_name = '',
                this.vendor.last_name = '',
                this.vendor.company = '',
                this.vendor.phone = '',
                this.vendor.email = ''
                this.vendor.password = ''
                Swal.fire({
                    title: "Error!",
                    text: 'You must enter all the mandatory fields (*)',
                    icon: "error"
                })
            }
        },

        goBack() {
            if (this.$route.query.redirect) {
                this.$router.push({
                    path: '/auth/login',
                    query: {
                        redirect: this.$route.query.redirect
                    }
                })
            } else {
                this.$router.push('/auth/login')
            }
        }
    }
};
</script>

<style scoped>
.vendor-width {
    width: 399px;
}

@media screen and (max-width: 550px) {
    .vendor-width {
        width: 300px;
    }
}

@media screen and (max-width: 420px) {
    .vendor-width {
        width: 280px;
    }
}
</style>
