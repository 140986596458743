<template>
<div>
    <Breadcrumbs main="My Workspace" title="Grievance Details" />
    <!-- Employee Details  -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" v-if="!loading && !error">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                <div>
                                    <b-badge variant="primary" class="mb-4">
                                        <h7 style="text-align: center;font-size: 14px;">Grievance Details</h7>
                                    </b-badge>
                                </div>
                                <div style="display: flex;">
                                    <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(grievance_detail)" title="Reply"></feather>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Grievance ID</label>
                                        <input class="form-control" type="text" placeholder="Enter grievance subject" v-model="grievance_detail.grievance_id" disabled>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Grievance Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="grievance_detail.date" disabled></datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Grievance Subject</label>
                                        <input class="form-control" type="text" placeholder="Enter grievance subject" v-model="grievance_detail.subject" disabled>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Grievance Details</label>
                                        <b-form-textarea id="textarea-auto-height" placeholder="Enter asset details..." v-model="grievance_detail.details" rows="4" disabled></b-form-textarea>
                                    </div>
                                </div>
                            </div>

                            <div style="display: flex; flex-direction: row; gap: 10px;">
                                <h7>Grievance Status:</h7>
                                <div>
                                    <!-- @click="editStatus(grievance_detail.status)" v-b-tooltip.hover title="Update Status" v-b-modal.modal-prevent-closing-edit -->
                                    <span v-if="grievance_detail.status == 'OPEN'">
                                        <b-badge pill variant="success">{{ grievance_detail.status }}</b-badge>
                                    </span>
                                    <!-- @click="editStatus(grievance_detail.status)" v-b-tooltip.hover title="Update Status" v-b-modal.modal-prevent-closing-edit -->
                                    <span v-if="grievance_detail.status == 'CLOSE'">
                                        <b-badge pill variant="danger">{{ grievance_detail.status }}</b-badge>
                                    </span>
                                </div>
                            </div>

                            <h6 class="mt-4 mb-2">Already Refered</h6>
                            <div class="row">
                                <!-- v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete" -->
                                <div class="text-center col-md-2 w-full" v-for="user in grievance_detail.refering_to" :key="user.id">
                                    <div class="w-100 mt-2 badge-style">
                                        <h7 style="width: 100%; text-align: center;">{{ user.full_name }}</h7>
                                        <!-- <b-icon icon="x" style="color: white;"></b-icon> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </px-card>
            </div>
            <div class="col-sm-12" v-else>
                <div class="loader-box">
                    <div class="loader-10"></div>
                </div>
            </div>
        </div>
    </div>

    <!-- Grievance Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Update Grievance Status" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Leave Allocate Delete Modal -->
    <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this refere?</div>
    </b-modal>

    <!-- Reply Create Modal -->
    <b-modal id="modal-scrollable" ref="modal" title="Grievance Replies" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleReplyOk">
        <h6>Already Refered</h6>
        <div class="row">
            <!-- v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete" -->
            <div class="text-center col-sm-6 w-full" v-for="user in this.my_reply.refer_to" :key="user.id">
                <div class="w-100 mb-2 badge-style">
                    <h7 style="width: 100%; text-align: center;">{{ user.full_name}}</h7>
                    <!-- <b-icon icon="x" style="color: white;"></b-icon> -->
                </div>
            </div>
        </div>

        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>

        <div style="margin-top: 30px;" v-if="ticket_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in ticket_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleGrievanceReplySubmit()">
                <button @click="handleGrievanceReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Multiselect from 'vue-multiselect';
import host, { myHost } from '../../../../_helpers/host';
import { mapState } from 'vuex';
import moment from "moment";

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            selectedStatus: '',
            selectedPriority: '',
            statuses: ['OPEN', 'CLOSE'],
            selectedStatus: '',

            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },

            temp_id: '',

            assignedMembers: [],
            filter: null,
            temp_id: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState({
            grievance_detail: state => state.grievance.grievance_detail,
            user_list: state => state.employee.employee_list,
            ticket_reply: state => state.grievance.ticket_reply,
            loading: state => state.grievance.submitLoading,
            error: state => state.grievance.submitError,

        }),
    },

    mounted() {
        this.$store.dispatch("grievance/fetchGrievanceDetails", this.$route.params.id);
        this.$store.dispatch("employee/fetchEmployeeList");
    },

    watch: {
        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        }
    },

    methods: {
        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },

        editStatus(s) {
            this.selectedStatus = s
        },

        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("grievance/grievanceAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId,
                details: true,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.my_reply.refer_to = type.refering_to
            this.$store.dispatch("grievance/fetchGrievanceReply", this.my_reply.id)
        },

        handleAllocationDeleteOk() {
            this.$store.dispatch("grievance/grievanceDeleteRefer", {
                id: this.$route.params.id,
                refer_id: this.temp_id,
                details: true,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            // this.$nextTick(() => {
            //     this.$bvModal.hide('modal-scrollable')
            // })
        },

        deletetAllocation(id) {
            this.temp_id = id
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {
            this.$store.dispatch("grievance/updateGrievanceStatus", {
                id: this.$route.params.id,
                status_for_change: this.selectedStatus
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.delete_value = null
                    this.selectedStatus = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        handleGrievanceReplySubmit() {
            this.$store.dispatch("grievance/addGrievanceReply", {
                grievance: this.my_reply.id,
                reply: this.my_reply.reply
            }).then(response => { 
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.my_reply.reply = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$store.dispatch("grievance/fetchGrievanceReply", this.my_reply.id)
        }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;
}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
    word-break: break-all;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
