<template>
<div>
    <Breadcrumbs main="HR"  title="Grievance" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header" style="width: 100%">
                        <div style="max-width: fit-content;">
                            <router-link to="/hr/archive-grievance/list" style="display: flex; gap: 12px; align-items: center; color: white;">
                                <button class="btn btn-primary" type="submit">
                                    <h7>Archive</h7>
                                </button>
                            </router-link>
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-wrap" style="gap: 20px;">
                                    <b-input-group class="datatable-btn" style="width: 340px;">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                        <feather type="filter"></feather>
                                        <h7>Filter</h7>
                                    </div>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="statusSelected ||  start_date || end_date">
                                        <feather type="x"></feather>
                                        <h7>Clear All</h7>
                                    </div>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="grievance_list" :fields="tablefields" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(subject)="data">
                                        <div @click="grievanceDetails(data.item)" class="link-style">
                                            <h7 v-b-tooltip.hover title="Details" v-b-modal.modal-prevent-closing-detail>{{data.item.subject}}</h7>
                                        </div>
                                    </template>

                                    <template #cell(status)="row">
                                        <span v-if="row.value == 'OPEN'" @click="editStatus(row.value, row.item.id)">
                                            <b-badge pill variant="success" v-b-tooltip.hover title="Status" v-b-modal.modal-prevent-closing-edit>{{ row.value }}</b-badge>
                                        </span>
                                        <span v-if="row.value == 'CLOSE'" @click="editStatus(row.value, row.item.id)">
                                            <b-badge pill variant="danger" v-b-tooltip.hover title="Status" v-b-modal.modal-prevent-closing-edit>{{ row.value }}</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(refering_to)="data">
                                        <span>
                                            <div v-if="data.item.refering_to !== null && data.item.refering_to.length > 0">
                                                <div v-for="user in data.item.refering_to" :key="user.id">
                                                    <p>{{ user.first_name }}</p>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <p>--</p>
                                            </div>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(data.item)" title="Reply"></feather>
                                            <feather type="archive" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteGrievance(data.item.id)" title="Archive"></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Grievance Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Update Grievance Status" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses_for_update"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Grievance Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to archive this grievance?</div>
    </b-modal>

    <!-- Reply Create Modal -->
    <b-modal id="modal-scrollable" ref="modal" title="Grievance Replies" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleReplyOk">

        <h6>Already Refered</h6>
        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="user in this.my_reply.refer_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ user.first_name}}</h7>
                    <b-icon icon="x" style="color: white;"></b-icon>
                </div>
            </div>
        </div>
        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>

        <div style="margin-top: 30px;" v-if="ticket_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in ticket_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleGrievanceReplySubmit()">
                <button @click="handleGrievanceReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>

    <!-- Leave Allocate Delete Modal -->
    <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this refere?</div>
    </b-modal>

    <!-- Grievance Detail Modal -->
    <b-modal id="modal-prevent-closing-detail" ref="modal" title="Grievance Details" hide-footer>
        <form ref="form" @submit.stop.prevent="handleEditSubmit">

            <b-form-group label="Reason" label-for="name-input">
                <b-form-input v-model="grievance_details.subject" disabled></b-form-input>
            </b-form-group>

            <div class="row">
                <div class="col-sm-6">
                    <b-form-group label="Created Date" label-for="name-input">
                        <datepicker placeholder="Select start date" input-class="datepicker-here form-control digits" v-model="grievance_details.date" disabled></datepicker>
                    </b-form-group>
                </div>

                <div class="col-sm-6">
                    <b-form-group label="Status" label-for="name-input">
                        <b-form-input v-model="grievance_details.status" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b-form-textarea id="textarea-rows" placeholder="Tall textarea" rows="5" v-model="grievance_details.details" disabled></b-form-textarea>
                </div>

            </div>

            <div class="text-center" style="margin-top: 10px;">
                <h6>Reffered To</h6>
            </div>
            <div class="row">
                <div class="text-center col-sm-6 w-full" v-for="user in grievance_details.refering_to" :key="user.id">
                    <div class="w-100 mb-2 badge-style">
                        <h7>{{ user.first_name }}</h7>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {
    mapState
} from 'vuex';
import {
    myHost
} from '../../../../_helpers/host';
import moment from 'moment';

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            filterShow: false,
            start_date: null,
            end_date: null,
            statusSelected: '',
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'date',
                    label: 'Grievance Date',
                    sortable: true
                },
                {
                    key: 'subject',
                    label: 'Subject',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'refering_to',
                    label: 'Reffered To',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            grievance_id: null,
            grievance: {
                subject: '',
                details: '',
                date: null,
                status: '',
                refering_to: [],
            },
            grievance_details: {
                id: '',
                subject: '',
                details: '',
                date: null,
                status: '',
                refering_to: [],
            },
            edit_grievance: {
                id: '',
            },
            selectedStatus: '',
            assignedMembers: [],
            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            temp_id: '',
            statuses_for_update: ['OPEN', 'CLOSE'],
            statuses: [{
                    id: 1,
                    name: 'Open',
                    value: 'OPEN'
                },
                {
                    id: 3,
                    name: 'Close',
                    value: 'CLOSE',
                }
            ],
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            initialLoad: true,
            isModifyingDate: false,
        };
    },

    computed: {
        ...mapState({
            grievance_list: state => state.grievance.grievance,
            count: state => state.grievance.grievance_count,
            user_list: state => state.employee.employee_list,
            ticket_reply: state => state.grievance.ticket_reply,
            loading: state => state.grievance.submitLoading,
            error: state => state.grievance.submitError
        }),

        formattedOptions() {
            return [{
                    value: '',
                    text: 'Select Status',
                    disabled: true
                },
                ...this.statuses.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        },
    },

    watch: {
        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/hr/grievance/list',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("grievance/fetchGrievance", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/hr/grievance/list',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("grievance/fetchGrievance", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        start_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.start_date !== null) {
                        this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");

                        this.$store.dispatch("grievance/fetchGrievance", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.statusSelected
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("grievance/fetchGrievance", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.statusSelected
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        },

        end_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.end_date !== null) {
                        this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("grievance/fetchGrievance", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.statusSelected
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("grievance/fetchGrievance", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.statusSelected
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        },

        statusSelected(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("grievance/fetchGrievance", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        filter(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("grievance/fetchGrievance", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        }
    },

    mounted() {
       
        this.$store.dispatch("employee/fetchEmployeeList");
        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {

                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitial();
            } else {

                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitial();
            }
        }
    },

    methods: {
        fetchInitial() {
            this.$store.dispatch("grievance/fetchGrievance", {
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false;
            });
        },
        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        clearFilter() {
            this.filterShow = false,
                this.filter = null,
                this.statusSelected = '',
                this.start_date = null,
                this.end_date = null
        },

        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },

        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.my_reply.refer_to = type.refering_to
            this.$store.dispatch("grievance/fetchGrievanceReply", this.my_reply.id)
        },

        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("grievance/grievanceAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page,
                all: true,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        handleAllocationDeleteOk() {
            this.$store.dispatch("grievance/grievanceDeleteRefer", {
                id: this.my_reply.id,
                refer_id: this.temp_id,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page,
                all: true,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        deletetAllocation(id) {
            this.temp_id = id
        },

        handleGrievanceReplySubmit() {
            this.$store.dispatch("grievance/addGrievanceReply", {
                grievance: this.my_reply.id,
                reply: this.my_reply.reply
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.my_reply.id = '',
                        this.my_reply.reply = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        grievanceDetails(type) {
            this.grievance_details.id = type.id
            this.grievance_details.subject = type.subject
            this.grievance_details.details = type.details
            this.grievance_details.status = type.status
            this.grievance_details.date = type.date
            this.grievance_details.refering_to = type.refering_to
        },

        deleteGrievance(id) {
            this.grievance_id = id
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.grievance.subject = ''
            this.grievance.date = null
            this.grievance.status = null
            this.grievance.refering_to = ''
            this.grievance.details = null
        },

        editStatus(status, id) {
            this.edit_grievance.id = id,
                this.selectedStatus = status
        },

        resetEditModal() {
            this.edit_grievance.id = ''
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {
            this.$store.dispatch("grievance/updateGrievanceStatus", {
                id: this.edit_grievance.id,
                status_for_change: this.selectedStatus,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null
                    this.selectedStatus = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        handleDeleteOk() {
            this.$store.dispatch("grievance/deleteGrievance", {
                delete: this.grievance_id,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;
}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
    word-break: break-all;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
